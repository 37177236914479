// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

export type ApplicationFrameContextProps = {
  /** */
  help: {
    /** */
    open: boolean;

    /** */
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  /** */
  menu: {
    /** */
    open: boolean;

    /** */
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  /** */
  launcher: {
    /** */
    open: boolean;

    /** */
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  /** */
  notification: {
    /** */
    open: boolean;

    /** */
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }
};

export const ApplicationFrameContext = React.createContext<ApplicationFrameContextProps>({
  help: {
    open: false,
    setOpen: null,
  },

  menu: {
    open: false,
    setOpen: null,
  },

  launcher: {
    open: false,
    setOpen: null,
  },

  notification: {
    open: false,
    setOpen: null,
  },
});
