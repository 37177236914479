// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Button, Icon, Menu,
  Ref, SemanticICONS,
} from 'semantic-ui-react';

import './ApplicationLauncherListItem.less';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

import { ApplicationLauncherPopup } from './ApplicationLauncherPopup';

import { Link } from '../../services/router';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('launcher', 'list', 'item');

export type ApplicationLauncherListItemProps = {
  /** Primary content. */
  children?: React.ReactNode;

  /** */
  name?: string;

  /** */
  code?: string;

  /** */
  icon?: string;

  /** */
  libelle?: string;

  /** */
  path?: string;
};

export type ApplicationLauncherListItemType = React.ComponentType<ApplicationLauncherListItemProps>;

export const ApplicationLauncherListItem: ApplicationLauncherListItemType = (
  props: ApplicationLauncherListItemProps,
) => {
  const {
    children,
    name,
    code,
    icon,
    libelle,
    path,
    ...rest
  } = props;

  const { setOpen } = React.useContext(ApplicationLauncherContext);

  const innerRef = React.useRef<HTMLButtonElement>();

  const handleMouseDown = React.useCallback(() => {
    innerRef.current?.scrollIntoView();
  }, []);

  const handleClick = React.useCallback(() => {
    React.startTransition(() => {
      setOpen(false);
    });
  }, []);

  const content = React.useMemo(() => (
    <Ref innerRef={innerRef}>
      <Button
        fluid
        onMouseDown={handleMouseDown}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(path && {
          as: Link,
          to: path,
          onClick: handleClick,
        })}
        data-testid={name}
        className={code}
      >
        <aside>
          <Icon name={(icon as SemanticICONS)} variant="solid" />
        </aside>
        <span>{libelle}</span>
      </Button>
    </Ref>
  ), [
    libelle,
    name,
    code,
    icon,
    path,
    handleClick,
    handleMouseDown,
  ]);

  const popup = React.useMemo(() => (
    <ApplicationLauncherPopup code={code} trigger={content}>
      {children}
    </ApplicationLauncherPopup>
  ), [
    children,
    content,
    code,
  ]);

  return React.useMemo(() => (
    <Menu.Item
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {
        path
          ? content
          : popup
      }
    </Menu.Item>
  ), [
    children,
    content,
    popup,
    path,
  ]);
};
