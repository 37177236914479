// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as Crypto from 'crypto-js';

const getSecret = (key: string) => (key + _.repeat(key, 32)).substring(0, 32);

export const decrypt = (data: unknown, key: string, serialize = true) => {
  const secret = Crypto.enc.Utf8.parse(getSecret(key));

  const payload = JSON.parse(Crypto.enc.Base64.parse(data as string).toString(Crypto.enc.Utf8));

  const iv = Crypto.enc.Base64.parse(payload.iv);
  const decrypted = Crypto.AES.decrypt(
    payload.value,
    secret,
    {
      iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7,
    },
  ).toString(Crypto.enc.Utf8);

  return serialize ? JSON.parse(decrypted) : decrypted;
};

export const encrypt = (data: unknown, key: string, serialize = true) => {
  const secret = Crypto.enc.Utf8.parse(getSecret(key));

  const iv = Crypto.lib.WordArray.random(16);
  const encrypted = Crypto.AES.encrypt(
    serialize ? JSON.stringify(data) : data as string,
    secret,
    {
      iv,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7,
    },
  ).toString();

  const payload = JSON.stringify({
    iv: Crypto.enc.Base64.stringify(iv),
    value: encrypted,
    mac: Crypto.HmacSHA256(iv + encrypted, key).toString(),
  });

  return Crypto.enc.Base64.stringify(Crypto.enc.Utf8.parse(payload));
};
