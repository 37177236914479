// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Segment } from 'semantic-ui-react';

import './ApplicationContainer.less';

import { getApplicationClassName, getClassName } from '../utils/className';

const className = getApplicationClassName('container');

export type ApplicationContainerProps = {
  /** Primary Content. */
  children?: React.ReactNode;
};

export type ApplicationContainerType = React.ComponentType<ApplicationContainerProps>;

export const ApplicationContainer: ApplicationContainerType = (props) => {
  const { children } = props;

  return (
    <Segment className={getClassName(className)} basic>
      <Segment>
        {children}
      </Segment>
    </Segment>
  );
};
