// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  ApplicationThemeContext,
  ApplicationThemeVariant,
} from './ApplicationThemeContext';

import { useTheme } from './hooks/useTheme';
import { ApplicationEnvironmentContext } from '../ApplicationEnvironment';

export type ApplicationThemeContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationThemeContextProviderType = React.ComponentType<ApplicationThemeContextProviderProps>;

const toThemeVariant = (domain): ApplicationThemeVariant => {
  const variant = domain.replace(/\..*/, '');

  return ['horizon', 'millesime', 'interco'].includes(variant) ? variant : 'auto';
};

export const ApplicationThemeContextProvider: ApplicationThemeContextProviderType = (
  props: ApplicationThemeContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationThemeContext;

  // const [color, setColor] = useTheme<ApplicationThemeColor>('color', 'blue');
  const [variant, setVariant] = useTheme<ApplicationThemeVariant>('variant', toThemeVariant(window.location.hostname));

  const environment = React.useContext(ApplicationEnvironmentContext);

  React.useEffect(() => {
    if (variant && variant !== 'auto') {
      document.body.classList.add(_.toString(variant));

      document.title = `${_.capitalize(variant)} 360 - Mairistem By JVS`;
    }

    switch (environment) {
      case 'development':
        document.title = `[DEV] ${document.title}`;
        break;
      case 'testing':
        document.title = `[QA] ${document.title}`;
        break;
      case 'staging':
        document.title = `[PP] ${document.title}`;
        break;
      default:
        break;
    }

    return () => {
      if (variant && variant !== 'auto') {
        document.body.classList.remove(_.toString(variant));

        document.title = 'Mairistem By JVS';
      }
    };
  }, [variant, environment]);

  return React.useMemo(() => (
    <Provider
      value={
                {
                  variant,
                  setVariant,
                }
            }
    >
      {children}
    </Provider>
  ), [
    variant,
    children,
  ]);
};
