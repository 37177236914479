import * as _ from 'lodash';

import { leven } from '@jvs-group/jvs-mairistem-tools';

export const levenSearch = (value, query) => _.every(
  _.split(query, ' '),
  (queryWord) => _.some(
    _.split(value, ' '),
    (valueWord) => leven(valueWord, queryWord) <= 2
        || _.startsWith(_.toLower(valueWord), _.toLower(queryWord)),
  ),
);
