// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Container, Popup, Icon, Header, Table,
} from 'semantic-ui-react';

import { ApplicationPageContext } from '../ApplicationPage';
import { ApplicationNavigationContext } from '../ApplicationNavigation';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationHelpModuleProps = { };

export type ApplicationHelpModuleType = React.ComponentType<ApplicationHelpModuleProps>;

export const ApplicationHelpModule: ApplicationHelpModuleType = () => {
  const { module } = React.useContext(ApplicationNavigationContext);
  const { manifest, loading } = React.useContext(ApplicationPageContext);

  const dependencies = React.useMemo(() => (
    <Table className="scrolling" compact style={{ width: '440px' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={11}>Nom</Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="right">Version</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(manifest?.dependencies, (version, name) => (
          <Table.Row key={`${name}-${version}`}>
            <Table.Cell width={11}>{name}</Table.Cell>
            <Table.Cell width={5} textAlign="right">{version}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ), [manifest?.dependencies]);

  const header = React.useMemo(() => manifest?.version && (
    <Container fluid>
      <Popup
        position="top right"
        on="click"
        trigger={(
          <Header>
            <Icon name="sitemap" />
            <Header.Content>
              {`${module?.libelle} ${manifest?.version}`}
              <Header.Subheader>{`${(new Date(manifest?.date as string)).toLocaleString()} (${manifest?.revision})`}</Header.Subheader>
            </Header.Content>
          </Header>
        )}
      >
        {dependencies}
      </Popup>
    </Container>
  ), [
    manifest?.date,
    manifest?.version,
    manifest?.revision,
    module?.libelle,
    dependencies,
  ]);

  return React.useMemo(() => (
    <Container className="module">
      {!loading && header}
    </Container>
  ), [
    header,
    loading,
  ]);
};
