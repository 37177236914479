// import * as _ from 'lodash';
import {
  AxiosError, AxiosRequestConfig, AxiosResponse,
} from 'axios';

import { useEventCallback } from '@jvs-group/jvs-mairistem-tools';

// import * as host from './host';
import * as toast from '../api/toast';

import Sentry from '../services/sentry';

import type { Module } from '../entities/Module';
import type { User } from '../entities/User';

export const useAxios = (user: User, module: Module) => {
  const requestData = useEventCallback((config: AxiosRequestConfig) => {
    // /** ************** */
    // /** ~~~~~~~~~~~~~~ */
    // /** ~ WORKAROUND ~ */
    // /*                 */
    // if (_.startsWith(config.url, '/api')) {
    //   if (_.isEmpty(config.baseURL)) {
    //     if (!_.startsWith(config.url, host.api(module?.repertoire))) {
    //       /* eslint-disable no-param-reassign */
    //       config.url = _.replace(config.url || '', '/api', '');
    //       config.baseURL = host.api(module?.repertoire);

    //       config.headers['X-Url-Base'] = config.baseURL;
    //       config.headers['X-Url-Path'] = config.url;
    //       /* eslint-enable no-param-reassign */

    //       /* eslint-disable no-console */
    //       console.error("[DEV]: active l'affichage des logs de debug");
    //       console.debug('  ╔╦╗  ╔╦═══╗ ╔═╗╔═╗          ╔╗       ');
    //       console.debug('  ║║╚╗╔╝║╔═╗║ ║║╚╝║║         ╔╝╚╗      ');
    //       console.debug('  ║╠╗║║╔╣╚══╗ ║╔╗╔╗╠══╦╦═╦╦══╬╗╔╬══╦╗╔╗');
    //       console.debug('╔╗║║║╚╝║╚══╗║ ║║║║║║╔╗╠╣╔╬╣══╣║║║║═╣╚╝║');
    //       console.debug('║╚╝║╚╗╔╝║╚═╝║ ║║║║║║╔╗║║║║╠══║║╚╣║═╣║║║');
    //       console.debug('╚══╝ ╚╝ ╚═══╝ ╚╝╚╝╚╩╝╚╩╩╝╚╩══╝╚═╩══╩╩╩╝');
    //       console.debug();
    //       console.debug('[AXIOS] url ne commence pas par le répertoire du module');
    //       console.debug(config);
    //       console.debug();
    //       console.debug();
    //       console.debug('Cette fonctionnalité sera supprimé prochainement !');
    //       /* eslint-enable no-console */
    //     }
    //   }

    //   let urlPath = '';
    //   if (/!api/.test(config.baseURL || '')) {
    //     urlPath = config.baseURL?.replace(/.*!api/, '') || '';
    //     // eslint-disable-next-line no-param-reassign
    //     config.baseURL = config.baseURL?.replace(/!/, '/');

    //     /* eslint-disable no-param-reassign */
    //     config.headers['X-Url-Base'] = config.baseURL?.replace(new RegExp(`${urlPath}$`), '');
    //     config.headers['X-Url-Path'] = urlPath + config.url;
    //     /* eslint-enable no-param-reassign */

    //     /* eslint-disable no-console */
    //     console.error("[DEV]: active l'affichage des logs de debug");
    //     console.debug('  ╔╦╗  ╔╦═══╗ ╔═╗╔═╗          ╔╗       ');
    //     console.debug('  ║║╚╗╔╝║╔═╗║ ║║╚╝║║         ╔╝╚╗      ');
    //     console.debug('  ║╠╗║║╔╣╚══╗ ║╔╗╔╗╠══╦╦═╦╦══╬╗╔╬══╦╗╔╗');
    //     console.debug('╔╗║║║╚╝║╚══╗║ ║║║║║║╔╗╠╣╔╬╣══╣║║║║═╣╚╝║');
    //     console.debug('║╚╝║╚╗╔╝║╚═╝║ ║║║║║║╔╗║║║║╠══║║╚╣║═╣║║║');
    //     console.debug('╚══╝ ╚╝ ╚═══╝ ╚╝╚╝╚╩╝╚╩╩╝╚╩══╝╚═╩══╩╩╩╝');
    //     console.debug();
    //     console.debug('[AXIOS] l\'url a été modifié, verifier les headers');
    //     console.debug(config);
    //     console.debug();
    //     console.debug();
    //     console.debug('Cette fonctionnalité sera supprimé prochainement !');
    //     /* eslint-enable no-console */
    //   }
    // }
    // /*                 */
    // /** ~~~~~~~~~~~~~~ */
    // /** ************** */

    /* eslint-disable no-param-reassign */
    config.headers.Authorization = `Bearer ${user?.token?.access}`;
    config.headers['X-User'] = user?.identifiant;
    config.headers['X-Tenant'] = user?.tenant.identifiant;
    config.headers['X-Component'] = user?.component.identifiant;
    config.headers['X-Produit'] = module?.identifiant;
    config.headers['X-Domaine'] = module?.serial?.domaine;
    config.headers['X-Produit-Domaine'] = module?.serial?.produit;
    /* eslint-enable no-param-reassign */

    return config;
  });

  const requestError = useEventCallback((error: Error) => Promise.reject(error));

  const responseData = useEventCallback((data: AxiosResponse) => data);

  const responseError = useEventCallback((error: AxiosError) => {
    toast.error(error.response.status);

    if ((error.response.status || 500) > 499) {
      Sentry?.captureException(error);
    }

    return Promise.reject(error);
  });

  return {
    request: { data: requestData, error: requestError },
    response: { data: responseData, error: responseError },
  };
};
