// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import React from 'react';
import {
  useLocation as useRouterLocation,
  matchPath as matchRouterPath,
} from 'react-router';
import {
  useHistory as useRouterHistory,
  Link as RouterLink,
  NavLink as RouterNavLink,
  Redirect as RouterNavigate,
  Router as RouterRouter,
  Route as RouterRoute,
  Switch as RouterRoutes,
} from 'react-router-dom';

export const useHistory = useRouterHistory;
export const useLocation = useRouterLocation;
// V6 -> V5
export const matchPath = ({ end, ...pattern }, pathname) => matchRouterPath(pathname, {
  ...pattern,
  exact: end,
});

export const Link = RouterLink;
export const NavLink = RouterNavLink;
export const Navigate = RouterNavigate;
export const Router = RouterRouter;
export const Routes = RouterRoutes;
// V6 -> V5
export const Route = ({ element, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RouterRoute {...rest}>{element}</RouterRoute>
);
