// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Icon } from 'semantic-ui-react';

import './ApplicationMenuLoader.less';

import * as i18n from '../../config/i18n';

import { ApplicationMenuItem } from './ApplicationMenuItem';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('menu', 'loader');

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationMenuLoaderProps = { };

export type ApplicationMenuLoaderType = React.ComponentType<ApplicationMenuLoaderProps>;

export const ApplicationMenuLoader: ApplicationMenuLoaderType = () => (
  <ApplicationMenuItem
    icon={<Icon className={className} loading name="spinner" />}
    content={i18n.menuLoading}
    disabled
  />
);
