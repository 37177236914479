// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';
import * as ReactDom from 'react-dom';

import { History, createBrowserHistory } from 'history';

import { useWindow } from '@jvs-group/jvs-mairistem-tools';

import Application from '../Application';

import { storeManager } from '../../services/redux';

export type ApplicationWindowProps = {
  /** */
  children: (props: { onOpen: () => void }) => React.ReactNode;

  /** */
  path: string;
};

export type ApplicationWindowType = React.ComponentType<ApplicationWindowProps>;

export const ApplicationWindow: ApplicationWindowType = (
  props: ApplicationWindowProps,
) => {
  const {
    children,
    path,
  } = props;

  const [history, setHistory] = React.useState<History>();
  const [open, setOpen] = React.useState(false);

  const [ready, setReady] = React.useState(false);

  const [window, container, load] = useWindow(path, open);

  const handleOpen = React.useCallback(() => {
    React.startTransition(() => {
      setOpen(true);
    });
  }, []);

  React.useEffect(() => {
    if (window) {
      React.startTransition(() => {
        setHistory(createBrowserHistory(({ window })));
      });
    }
  }, [window]);

  React.useEffect(() => {
    if (!load) {
      React.startTransition(() => {
        setOpen(false);
      });
    }
  }, [load]);

  React.useEffect(() => {
    React.startTransition(() => {
      setReady(open && !!window && !!container && !!history);
    });
  }, [
    window,
    container,
    history,
    open,
  ]);

  const content = React.useMemo(() => (
    <Application history={history} store={storeManager.store} />
  ), [history]);

  const portal = React.useMemo(() => (
    ready && ReactDom.createPortal(content, container)
  ), [ready, content, container]);

  return (
    <>
      {children({ onOpen: handleOpen })}
      {portal}
    </>
  );
};
