// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  List, Popup,
  SemanticICONS,
} from 'semantic-ui-react';

import './ApplicationMenuItem.less';

import {
  ApplicationList,
  ApplicationListItem,
  ApplicationListItemProps,
} from '../ApplicationList';

import { ApplicationMenuContext } from './ApplicationMenuContext';

import { Link, matchPath, useLocation } from '../../services/router';

import { MenuBracketIcon } from '../../assets/icons/MenuBracket';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

const className = getApplicationClassName('menu', 'item');

export type ApplicationMenuItemProps = ApplicationListItemProps & {
  /** */
  children?: React.ReactNode;

  /** */
  content?: React.ReactNode;

  /** */
  title?: React.ReactNode;

  /** */
  items?: React.ReactElement[];

  /** */
  icon?: string | React.ReactElement;

  /** */
  loading?: boolean;

  /** */
  fetching?: boolean;

  /** */
  disabled?: boolean;

  /** */
  link?: boolean;

  /** */
  exact?: boolean;

  /** */
  path?: string;

  /** */
  name?: string;

  /** */
  offset?: number;

  /** */
  sortable?: boolean;

  /** */
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    data: ApplicationMenuItemProps,
  ) => void;
};

export type ApplicationMenuItemType = React.ComponentType<ApplicationMenuItemProps>;

export const ApplicationMenuItem: ApplicationMenuItemType = (
  props: ApplicationMenuItemProps,
) => {
  const {
    children,
    content,
    actions,
    title,
    items,
    icon,
    path,
    exact,
    link,
    name,
    loading,
    disabled,
    offset = 0,
    sortable,
    onClick,
    ...rest
  } = props;

  const {
    open, setOpen,
    item, setItem,
  } = React.useContext(ApplicationMenuContext);

  const location = useLocation();

  const [active, setActive] = React.useState(false);
  const [selected, setSelected] = React.useState(false);

  const handleClick = React.useCallback((e) => {
    if (setItem) {
      if (name && (!selected || open)) {
        React.startTransition(() => {
          setItem(name);
        });
      }
    }

    if (setOpen) {
      React.startTransition(() => {
        setOpen(!_.isEmpty(items));
      });
    }

    onClick?.(e, props);
  }, [
    props,
    name,
    open,
    items,
    selected,
    setItem,
    setOpen,
    onClick,
  ]);

  React.useEffect(() => {
    React.startTransition(() => {
      setSelected(_.startsWith(item, name));
    });
  }, [name, item]);

  React.useEffect(() => {
    React.startTransition(() => {
      setActive(!offset && path && !!matchPath({ path, end: exact }, location.pathname));
    });
  }, [
    offset,
    path,
    exact,
    location,
  ]);

  const contentComponent = React.useMemo(() => (
    <List.Item
      as={link ? Link : ''}
      to={path}
      aria-label={children || content}
      data-testid={name}
      className={getClassName(className, { active })}
      disabled={disabled}
      onClick={handleClick}
      style={{ paddingLeft: `${offset + 1}em` }}
    >
      {React.isValidElement(icon) ? icon : <List.Icon name={(icon as SemanticICONS)} />}
      <div style={{ display: open ? 'flex' : 'none', width: '100%' }}>
        <List.Content verticalAlign="middle">
          {children || content}
        </List.Content>
        {!_.isEmpty(items) && <MenuBracketIcon active={selected} />}
      </div>
    </List.Item>
  ), [
    active,
    offset,
    link,
    path,
    icon,
    loading,
    disabled,
    children,
    content,
    name,
    open,
    handleClick,
  ]);

  const itemsList = React.useMemo(() => (
    !(_.isEmpty(items) || loading)
        && (
          <ApplicationList
            items={items}
            sortable={sortable}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        )
  ), [
    items,
    loading,
    sortable,
    rest,
  ]);

  const itemComponent = React.useMemo(() => (
    <ApplicationListItem
      path={path}
      name={name}
      link={link}
      content={content}
      title={title}
      offset={offset + 4}
      direction="row"
      disabled={disabled}
      actions={actions}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  ), [
    content,
    title,
    path,
    link,
    offset,
    actions,
    rest,
  ]);

  const popup = React.useMemo(() => (
    <Popup
      trigger={contentComponent}
      disabled={!content || (open || !!offset)}
      position="right center"
    >
      {content}
    </Popup>
  ), [
    open,
    offset,
    selected,
    content,
    contentComponent,
    itemsList,
  ]);

  const nestedComponent = React.useMemo(() => (
    <>
      {popup}
      {!_.isEmpty(items) && (
      <div className={getClassName({ selected: selected && open })}>
        {selected && open && itemsList}
      </div>
      )}
    </>
  ), [
    popup,
    items,
    itemsList,
    selected,
    open,
  ]);

  return React.useMemo(() => (
    _.isEmpty(items) && offset
      ? itemComponent
      : nestedComponent
  ), [
    offset,
    items,
    itemComponent,
    nestedComponent,
  ]);
};
