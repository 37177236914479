// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import type { Domain } from '../../entities/Domain';
import type { Feature } from '../../entities/Feature';
import type { Module } from '../../entities/Module';

export type ApplicationNavigationContextProps = {
  /** */
  domain: Domain;

  /** */
  module: Module;

  /** */
  feature: Feature;
};

export const ApplicationNavigationContext = React.createContext<ApplicationNavigationContextProps>({
  domain: null,
  module: null,
  feature: null,
});
