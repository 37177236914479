// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import axios from 'axios';

import { cache } from './cache';

import Sentry from '../services/sentry';

import * as toast from './toast';

import privateApi from './private';
import publicApi from './public';

class ErrorRequest extends Error {
  public request;
  constructor(request) {
    super('request')
    this.request = request;
  }
}

const requestInterceptor = (config) => {
  if (cache.isPending(config) || cache.isCached(config)) {
    throw new ErrorRequest(config);
  }

  cache.addPendingRequest(config)

  return config;
}

const responseInterceptor = (response) => {
  cache.delPendingRequest(response.config)
  cache.setCachedResponse(response.config, response);
  
  return response;
}

const responseError = (error) => {
  if(error instanceof ErrorRequest){
    return cache.getCachedResponse(error.request);
  }

  cache.delPendingRequest(error.config);

  if (axios.isCancel(error)) {
    // @ts-ignore
    cache.clear(error.config)
  } else {
    toast.error(error.response.status);

    if (error.response.status > 499) {
      Sentry?.captureException(error);
    }
  }

  throw error;
}

privateApi.interceptors.request.use(requestInterceptor);
privateApi.interceptors.response.use(responseInterceptor, responseError);

publicApi.interceptors.request.use(requestInterceptor);
publicApi.interceptors.response.use(responseInterceptor, responseError);

export const retry = (config) => axios(config)

export const clear = () => {
  cache.clear();
  
  localStorage.clear();
  sessionStorage.clear();
}

export { default as publicApi } from './public';
export { default as privateApi } from './private';
