// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ToastContainer } from 'react-toastify';

import type { History } from 'history';
import type { Store } from 'redux';

import Sentry from '../../services/sentry';

import { ReduxProvider, storeManager } from '../../services/redux';
import { Router as RouterProvider, Routes, Route } from '../../services/router';

import 'react-toastify/dist/ReactToastify.css';

import * as routes from '../../config/routes';

import { ApplicationRoute } from '../ApplicationRoute';
import { ApplicationLoader } from '../ApplicationLoader';
import { ApplicationEnvironment } from '../ApplicationEnvironment';

import { ApplicationSessionContextProvider } from '../ApplicationSession';
import { ApplicationThemeContextProvider } from '../ApplicationTheme';

const ApplicationPage = React.lazy(() => import('../ApplicationPage'));
const ApplicationAuthentication = React.lazy(() => import('../ApplicationAuthentication'));

export type ApplicationProps = {
  /** */
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;

  /** */
  history: History;

  /** */
  store: Store
};

export type ApplicationType = React.ComponentType<ApplicationProps>;

export const Application: ApplicationType = (props: ApplicationProps) => {
  const {
    store,
    history,
    children,
  } = props;

  React.useEffect(() => {
    storeManager.store = store;
    // if (window.Cypress) {
    //   window.store = store;
    // }
  }, []);

  const content = React.useMemo(() => (
    <Routes>
      <Route
        path={routes.authenticationLogin}
        element={<ApplicationRoute component={ApplicationAuthentication} />}
      />
      <Route
        path={routes.userHome}
        element={<ApplicationRoute component={ApplicationPage} authenticate />}
      />
    </Routes>
  ), []);

  return (
    <ApplicationEnvironment>
      <React.Suspense fallback={<ApplicationLoader type="screen" />}>
        <ReduxProvider store={store}>
          <RouterProvider history={history}>
            <ApplicationSessionContextProvider>
              <ApplicationThemeContextProvider>
                { children || content}
                <ToastContainer position="bottom-right" draggable={false} />
              </ApplicationThemeContextProvider>
            </ApplicationSessionContextProvider>
          </RouterProvider>
        </ReduxProvider>
      </React.Suspense>
    </ApplicationEnvironment>
  );
};

export default Sentry?.withProfiler(Application, { name: 'Application' }) || Application;
