// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { useLocation } from '../../services/router';

import { ApplicationNavigationContext } from './ApplicationNavigationContext';

import { DomainService } from '../../entities/Domain';

import { ModuleService } from '../../entities/Module';
import { FeatureService } from '../../entities/Feature';

export type ApplicationNavigationContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationNavigationContextProviderType = React.ComponentType<ApplicationNavigationContextProviderProps>;

const useCurrentModuleByPage = (disabled?: boolean) => {
  const { pathname } = useLocation();

  const [module] = ModuleService.queryModuleByPage(pathname, disabled);

  return module;
};

const useCurrentFeatureByPage = (disabled?: boolean) => {
  const { pathname } = useLocation();

  const [feature] = FeatureService.queryFeatureByPage(pathname, disabled);

  return feature;
};

const useCurrentDomainByPage = (disabled?: boolean) => {
  const module = useCurrentModuleByPage(disabled);

  const [domain] = DomainService.queryDomain(module?.domaine?.identifiant);

  return domain;
};

export const ApplicationNavigationContextProvider: ApplicationNavigationContextProviderType = (
  props: ApplicationNavigationContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationNavigationContext;

  const currentDomain = useCurrentDomainByPage();
  const currentModule = useCurrentModuleByPage();
  const currentFeature = useCurrentFeatureByPage();

  React.useLayoutEffect(() => {
    if (currentDomain?.code) {
      document.body.classList.add(_.toString(currentDomain.code));
    }

    return () => {
      if (currentDomain?.code) {
        document.body.classList.remove(_.toString(currentDomain.code));
      }
    };
  }, [currentDomain]);

  return React.useMemo(() => (
    <Provider
      value={
              {
                domain: currentDomain,
                module: currentModule,
                feature: currentFeature,
              }
            }
    >
      {children}
    </Provider>
  ), [
    currentDomain,
    currentModule,
    currentFeature,
    children,
  ]);
};
