// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';

import {
  List, Transition, Segment, Ref,
} from 'semantic-ui-react';

import './ApplicationList.less';

import { useSize } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationListItem } from './ApplicationListItem';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

export type ApplicationListProps = {
  /** */
  className?: string;

  /** */
  children?: React.ReactNode;

  /** */
  divided?: boolean;

  /** */
  items?: React.ReactElement[];

  /** */
  loading?: boolean;

  /** */
  placeholder?: React.ReactNode;

  /** */
  virtualized?: boolean;

  /** */
  rowHeight?: number;

  /** */
  rowThreshold?: number;

  /** */
  removable?: boolean;

  /** */
  sortable?: boolean;

  /** */
  onSort?: (oldIndex: number, newIndex: number) => void;

  /** */
  onRemove?: (id: number) => void;

};

export type ApplicationListType = React.ComponentType<ApplicationListProps>;

export const ApplicationList: ApplicationListType = (
  props: ApplicationListProps,
) => {
  const {
    className,
    children,
    divided,
    items,
    loading,
    placeholder,
    virtualized,
    rowHeight = 30,
    rowThreshold = 5,
    sortable,
    removable,
    onSort,
    onRemove,
  } = props;

  const ref = React.useRef();

  const { height: containerHeight } = useSize(ref);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragSortEnd = React.useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      onSort?.(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _.findIndex(items, (i) => active.id === (i.identifiant || i.props.identifiant)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _.findIndex(items, (i) => over.id === (i.identifiant || i.props.identifiant)),
      );
    }
  }, [items]);

  const handleDragRemoveEnd = React.useCallback((event) => {
    const { active, delta } = event;

    if (delta.x > 40) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const item = _.find(items, (i) => active.id === (i.identifiant || i.props.identifiant));

      onRemove?.(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (item.identifiant || item.props.identifiant),
      );
    }
  }, [items]);

  const handleScroll = React.useMemo(
    () => _.throttle((e) => {
      setScrollPosition(e.target.scrollTop);
    }, 50, { leading: true }),
    [],
  );

  const loadingItems = React.useMemo(() => _.map(
    Array(5),
    (index, key) => (
      <ApplicationListItem
        key={key}
        placeholder
      />
    ),
  ), []);

  const contentItems = React.useMemo(() => children || _.map(items, (item, key) => (
    !React.isValidElement(item)
      ? React.createElement(ApplicationListItem, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        key: item.key || key,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...item,
      })
      : React.cloneElement(item, {
        key: item.key || key,
      })
  )), [
    children,
    items,
  ]);

  const listItems = React.useMemo(() => {
    if (!virtualized) {
      return contentItems;
    }

    const startIndex = Math.max(
      Math.floor(scrollPosition / rowHeight) - rowThreshold,
      0,
    );
    const endIndex = Math.min(
      Math.ceil((scrollPosition + containerHeight) / rowHeight - 1) + rowThreshold,
      contentItems.length - 1,
    );

    return contentItems
      .slice(startIndex, endIndex + 1)
      .map((child, index) => React.cloneElement(child, {
        style: {
          position: 'absolute',
          top: (startIndex + index) * rowHeight + index,
          height: rowHeight,
          left: 0,
          right: 0,
          lineHeight: `${rowHeight}px`,
        },
      }));
  }, [
    contentItems,
    containerHeight,
    rowHeight,
    scrollPosition,
    virtualized,
  ]);

  const placeholderContent = React.useMemo(() => (
    <Segment
      className={getClassName(className, getApplicationClassName('list'))}
      placeholder
    >
      {placeholder}
    </Segment>
  ), [
    className,
    placeholder,
  ]);

  const listContent = React.useMemo(() => (
    <Transition.Group
      className={getClassName(className, getApplicationClassName('list'), { virtualized })}
      as={List}
      divided={divided}
      selection
      link
      onScroll={handleScroll}
    >
      {listItems}
    </Transition.Group>
  ), [
    className,
    divided,
    listItems,
    virtualized,
    handleScroll,
  ]);

  const sortableContent = React.useMemo(() => (
    <Transition.Group
      className={getClassName(className, getApplicationClassName('list'), { virtualized })}
      as={List}
      divided={divided}
      selection
      link
      onScroll={handleScroll}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        onDragEnd={handleDragSortEnd}
      >
        <SortableContext
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items={_.map(listItems, (i) => ({ id: i.identifiant || i.props.identifiant }))}
          strategy={verticalListSortingStrategy}
        >
          {listItems}
        </SortableContext>
      </DndContext>
    </Transition.Group>
  ), [
    className,
    divided,
    listItems,
    virtualized,
    handleDragSortEnd,
    handleScroll,
  ]);

  const removableContent = React.useMemo(() => (
    <Transition.Group
      className={getClassName(className, getApplicationClassName('list'), { virtualized })}
      as={List}
      divided={divided}
      selection
      link
      onScroll={handleScroll}
    >
      <DndContext
        autoScroll={false}
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragRemoveEnd}
      >
        {listItems}
      </DndContext>
    </Transition.Group>
  ), [
    className,
    divided,
    listItems,
    virtualized,
    handleDragRemoveEnd,
    handleScroll,
  ]);

  const displayContent = React.useMemo(() => (
    <Ref innerRef={ref}>
      {
      // eslint-disable-next-line
      sortable
        ? sortableContent
        : removable
          ? removableContent
          : listContent
          }
    </Ref>
  ), [
    sortable,
    sortableContent,
    removable,
    removableContent,
    listContent,
  ]);

  const loadingList = React.useMemo(() => (
    <List
      className={getClassName(className, getApplicationClassName('list'))}
      selection
      link
    >
      {loadingItems}
    </List>
  ), [
    className,
    loadingItems,
  ]);

  const contentList = React.useMemo(() => (
    !React.Children.count(children) && _.isEmpty(items)
      ? placeholderContent
      : displayContent
  ), [
    children,
    items,
    displayContent,
    placeholderContent,
  ]);

  return (
    loading
      ? loadingList
      : contentList
  );
};
