// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const search = 'Rechercher';
export const searchPlaceholder = 'Rechercher un élément ...';
export const searchFeaturePlaceholder = 'Rechercher une fonctionnalité ...';
export const searchNoResultMessage = 'Aucun résultat trouvé';
export const searchNoResultDescription = 'Vous pouvez réessayer avec une nouvelle recherche.';

export const searchFilter = 'Filtrer les résultats';
