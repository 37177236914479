// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as routes from '../../config/routes';

import { ApplicationSessionContext } from '../ApplicationSession';

import { ApplicationLoader } from '../ApplicationLoader';

import { UserService } from '../../entities/User';

import { Navigate, useLocation } from '../../services/router';

export type ApplicationRouteProps = {
  /** */
  component?: React.ComponentType;

  /** */
  path?: string;

  /** */
  authenticate?: boolean;
};

export type ApplicationRouteType = React.ComponentType<ApplicationRouteProps>;

export const ApplicationRoute: ApplicationRouteType = (
  props: ApplicationRouteProps,
) => {
  const {
    component: Component,
    authenticate,
  } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const location = useLocation();

  return React.useMemo(() => (
    !authenticate || UserService.isValid(user)
      ? (
        <React.Suspense fallback={<ApplicationLoader type="screen" />}>
          <Component />
        </React.Suspense>
      )
      : (
        <Navigate
          to={{
            pathname: routes.authenticationLogin,
            state: { from: location, disconnected: true },
          }}
        />
      )
  ), [
    user,
    location,
    authenticate,
  ]);
};
