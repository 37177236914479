/* eslint-disable react/require-default-props */
import React from 'react';
import { Breadcrumb, SemanticICONS } from 'semantic-ui-react';

type ApplicationBreadcrumbSeparatorProps = {
  icon?: SemanticICONS;
};

const ApplicationBreadcrumbSeparator = ({ icon = 'arrow right' }: ApplicationBreadcrumbSeparatorProps) => (
  <Breadcrumb.Divider icon={icon} />
);

export default ApplicationBreadcrumbSeparator;
