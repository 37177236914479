// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Sidebar } from 'semantic-ui-react';

import { useSessionStorage } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationFrameContext } from './ApplicationFrameContext';

import { ApplicationHelp } from '../ApplicationHelp';

export type ApplicationFrameHelpProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationFrameHelpType = React.ComponentType<ApplicationFrameHelpProps>;

export const ApplicationFrameHelp: ApplicationFrameHelpType = (
  props: ApplicationFrameHelpProps,
) => {
  const { children } = props;

  const { help: { open } } = React.useContext(ApplicationFrameContext);

  const [pin, setPin] = useSessionStorage('interaction.help.pin', false);

  const handlePin = React.useCallback(() => {
    setPin((p) => !p);
  }, []);

  return React.useMemo(() => (
    <Sidebar.Pushable>
      <ApplicationHelp pin={pin} onPin={handlePin} />
      <Sidebar.Pusher dimmed={open && !pin}>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  ), [
    open,
    pin,
    children,
    handlePin,
  ]);
};
