// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import './ApplicationFooter.less';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('footer');

export type ApplicationFooterProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationFooterType = React.ComponentType<ApplicationFooterProps>;

export const ApplicationFooter: ApplicationFooterType = () => (
  <div className={className} />
);
