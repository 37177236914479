// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Sidebar, Segment, List,
} from 'semantic-ui-react';

import './ApplicationMenu.less';

import { ApplicationMenuContextProvider } from './ApplicationMenuContextProvider';

import { ApplicationNavigationContext } from '../ApplicationNavigation';
import { ApplicationFrameContext } from '../ApplicationFrame';
import { ApplicationPageContext } from '../ApplicationPage';

// import { ApplicationMenuAction } from './ApplicationMenuAction';
import { ApplicationMenuSearch } from './ApplicationMenuSearch';
import { ApplicationMenuLoader } from './ApplicationMenuLoader';
import { ApplicationMenuItem } from './ApplicationMenuItem';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';
import { MenuIcon } from '../../assets/icons/Menu';

const className = getApplicationClassName('menu');

const HistoryMenu = React.lazy(
  () => import('../../entities/History')
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
    .then(({ HistoryMenu }) => ({ default: HistoryMenu })),
);

const FavoriteMenu = React.lazy(
  () => import('../../entities/Favorite')
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
    .then(({ FavoriteMenu }) => ({ default: FavoriteMenu })),
);

const MenuMenu = React.lazy(
  () => import('../../entities/Menu')
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
    .then(({ MenuMenu }) => ({ default: MenuMenu })),
);

const SpeedDialMenu = React.lazy(
  () => import('../../entities/SpeedDial')
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
    .then(({ SpeedDialMenu }) => ({ default: SpeedDialMenu })),
);

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationMenuProps = { };

export type ApplicationMenuType = React.ComponentType<ApplicationMenuProps>;

export const ApplicationMenu: ApplicationMenuType = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: ApplicationMenuProps,
) => {
  const { module } = React.useContext(ApplicationNavigationContext);

  const { menu: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const { loading } = React.useContext(ApplicationPageContext);

  const [search, setSearch] = React.useState('');

  const handleShow = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(true);
      });
    }
  }, [setOpen]);

  const handleHide = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(false);
      });
    }
  }, [setOpen]);

  const handleSearch = React.useCallback((value) => {
    React.startTransition(() => {
      setSearch(value);
    });
  }, []);

  React.useEffect(() => {
    React.startTransition(() => {
      setSearch('');
    });
  }, [module]);

  return React.useMemo(() => (
    <ApplicationMenuContextProvider>
      <Sidebar
        className={getClassName(className)}
        as={Segment}
        visible={open}
        animation="overlay"
        direction="left"
        width="wide"
        onShow={handleShow}
        onHide={handleHide}
      >
        <List selection>
          <ApplicationMenuItem
            name="menu.toggle"
            icon={<MenuIcon active={open} />}
            content={open ? 'Fermer le menu' : 'Ouvrir le menu'}
            onClick={open ? handleHide : handleShow}
          />
          <hr style={{ borderColor: 'transparent', marginBlockStart: '0em' }} />
          <React.Suspense fallback={<ApplicationMenuLoader />}>
            <HistoryMenu key="history" module={module} />
          </React.Suspense>
          <hr />
          <React.Suspense fallback={<ApplicationMenuLoader />}>
            <FavoriteMenu key="favorite" module={module} />
          </React.Suspense>
          <ApplicationMenuSearch value={search} onChange={handleSearch} />
          {
            loading
              ? <ApplicationMenuLoader />
              : (
                <React.Suspense fallback={<ApplicationMenuLoader />}>
                  <MenuMenu key="menu" module={module} search={search} />
                </React.Suspense>
              )
          }
          <React.Suspense fallback={<ApplicationMenuLoader />}>
            <SpeedDialMenu key="speedial" module={module} />
          </React.Suspense>
        </List>
      </Sidebar>
    </ApplicationMenuContextProvider>
  ), [
    open,
    search,
    module,
    loading,
    handleShow,
    handleHide,
  ]);
};
