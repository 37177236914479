// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';
// import * as ReactDOM from 'react-dom/client';

import { ApplicationLoader } from '../ApplicationLoader';

export type ApplicationViewProps = {
  /** */
  scope: 'content' | 'header' | 'footer';

  /** */
  component: string;

  /** */
  // container: React.MutableRefObject<HTMLElement>;
};

export type ApplicationViewType = React.ComponentType<ApplicationViewProps>;

export const ApplicationView: ApplicationViewType = (
  props: ApplicationViewProps,
) => {
  const {
    scope,
    component,
    // container,
  } = props;

  const Component = React.lazy(() => import(`../../views/${component}`));

  const view = (
    <React.Suspense fallback={<ApplicationLoader type={scope === 'content' && 'overlay'} />}>
      <Component />
    </React.Suspense>
  );

  // React.useEffect(() => {
  //   const root = ReactDOM.createRoot(container.current);

  //   root.render(view);

  //   return () => {
  //     root.unmount();
  //   };
  // }, [view]);

  return view;
};
