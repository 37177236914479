// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  SwitchTransition, CSSTransition,
} from 'react-transition-group';

import {
  Grid, Image, Container,
} from 'semantic-ui-react';

import './ApplicationLoader.less';

import * as i18n from '../../config/i18n';

import { ApplicationThemeContext } from '../ApplicationTheme';

import { ApplicationContainer } from '../ApplicationContainer';

import { getApplicationClassName, getClassName } from '../utils/className';

const className = getApplicationClassName('loader');

export type ApplicationLoaderProps = {
  /** */
  type?: 'overlay' | 'screen' | '';

  /** */
  message?: string;
};

export type ApplicationLoaderType = React.ComponentType<ApplicationLoaderProps>;

export const ApplicationLoader: ApplicationLoaderType = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    type,
    message,
  } = props;

  const { variant } = React.useContext(ApplicationThemeContext);

  const [messaging, setMessaging] = React.useState(message || i18n.loaderRandomMessage());

  const loader = React.useMemo(() => (
    <div className={getClassName(className, 'dots')}>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  ), [className]);

  const messager = React.useMemo(() => (
    <SwitchTransition>
      <CSSTransition
        key={messaging}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        classNames="fade"
      >
        <span className="message">{messaging}</span>
      </CSSTransition>
    </SwitchTransition>
  ), [messaging]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!message) {
      interval = setInterval(() => {
        setMessaging(i18n.loaderRandomMessage());
      }, 10000);
    }

    return () => {
      if (!message) {
        clearInterval(interval);
      }
    };
  }, [message]);

  switch (type) {
    case 'screen':
      return (
        <Grid className={getClassName(className, type)}>
          <Grid.Row className="header">
            {/* <Image className="company" fluid />
              <span>L’atout numérique des collectivités</span> */}
          </Grid.Row>
          <Grid.Row className="content" centered>
            <Image className={getClassName('logo', 'colored')} aria-label={variant} />
            <Container className="indicator">
              {loader}
              {messager}
            </Container>
          </Grid.Row>
        </Grid>
      );

    case 'overlay':
      return (
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <ApplicationContainer>
            <Grid className={getClassName(className, type)}>
              <Grid.Row className="content" centered>
                <Image className={getClassName('logo', 'colored')} aria-label={variant} />
                <Container className="indicator">
                  {loader}
                  {messager}
                </Container>
              </Grid.Row>
            </Grid>
          </ApplicationContainer>
        </div>
      );

    default:
      return loader;
  }
};
