// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationFrameContext } from '../ApplicationFrame';

import { ApplicationNotificationContext } from './ApplicationNotificationContext';

export type ApplicationNotificationContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationNotificationContextProviderType = React.ComponentType<ApplicationNotificationContextProviderProps>;

// eslint-disable-next-line max-len
export const ApplicationNotificationContextProvider: ApplicationNotificationContextProviderType = (props) => {
  const {
    children,
  } = props;

  const { Provider } = ApplicationNotificationContext;

  const { notification: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  return React.useMemo(() => (
    <Provider
      value={
                {
                  open,
                  setOpen,
                }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    open,
    setOpen,
  ]);
};
