// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { useSessionStorage } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationFrameContext } from '../ApplicationFrame';

import { ApplicationMenuContext } from './ApplicationMenuContext';

export type ApplicationMenuContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationMenuContextProviderType = React.ComponentType<ApplicationMenuContextProviderProps>;

export const ApplicationMenuContextProvider: ApplicationMenuContextProviderType = (props) => {
  const {
    children,
  } = props;

  const { Provider } = ApplicationMenuContext;

  const { menu: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const [item, setItem] = useSessionStorage('interaction.menu.item', '');

  const [visible, setVisible] = useSessionStorage('interaction.menu.visible', false);

  const setSafeItem = React.useCallback((i) => {
    React.startTransition(() => {
      setItem((s) => {
        if (s === i) {
          if (_.split(i, '.').length > 1) {
            return _.join(_.dropRight(_.split(i, '.')), '.');
          }

          return null;
        }
        if (_.startsWith(s, i)) return null;

        return i;
      });
    });
  }, []);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => setVisible(true), 300);
    } else {
      React.startTransition(() => {
        setVisible(false);
      });
    }
  }, [open]);

  return React.useMemo(() => (
    <Provider
      value={
                {
                  open: visible,
                  setOpen,

                  item,
                  setItem: setSafeItem,
                }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    visible,
    open,
    item,
    setOpen,
    setSafeItem,
  ]);
};
