// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Menu } from 'semantic-ui-react';

import './ApplicationHeader.less';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

const className = getApplicationClassName('header');

export declare type ApplicationHeaderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationHeaderType = React.ComponentType<ApplicationHeaderProps>;

export const ApplicationHeader: ApplicationHeaderType = (
  props: ApplicationHeaderProps,
) => {
  const {
    children,
  } = props;

  return React.useMemo(() => (
    <Menu
      className={getClassName(className)}
      attached="top"
      size="small"
      borderless
      compact
      fluid
      secondary
    >
      {children}
    </Menu>
  ), [children]);
};
