// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Popup, Button,
} from 'semantic-ui-react';

import './ApplicationListItemAction.less';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

export type ApplicationListItemActionProps = {
  /** */
  className?: string;

  /** */
  content?: React.ReactNode;

  /** */
  icon?: React.ReactNode;

  /** */
  title?: string;

  /** */
  titlePosition?:
  | 'top left'
  | 'top right'
  | 'bottom right'
  | 'bottom left'
  | 'right center'
  | 'left center'
  | 'top center'
  | 'bottom center';

  /** */
  disabled?: boolean;

  /** */
  loading?: boolean;

  /** */
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    data: ApplicationListItemActionProps,
  ) => void;

  /** */
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
};

export type ApplicationListItemActionType = React.ComponentType<ApplicationListItemActionProps>;

export const ApplicationListItemAction: ApplicationListItemActionType = (
  props: ApplicationListItemActionProps,
) => {
  const {
    className,
    content,
    icon,
    title,
    titlePosition,
    disabled,
    loading,
    onClick,
    onMouseDown,
  } = props;

  const handleClick = React.useCallback((event) => {
    onClick?.(event, props);

    event.preventDefault();
    event.stopPropagation();
  }, [onClick]);

  const handleMouseDown = React.useCallback((event) => {
    onMouseDown?.(event);

    event.preventDefault();
    event.stopPropagation();
  }, [onMouseDown]);

  const triggerComponent = React.useMemo(() => (
    <Button
      className={getClassName(className, getApplicationClassName('list', 'item', 'action'))}
      aria-label={title}
      content={content}
      icon={icon}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    />
  ), [
    className,
    content,
    icon,
    title,
    disabled,
    loading,
    handleClick,
    handleMouseDown,
  ]);

  const contentComponent = React.useMemo(() => (
    title
  ), [title]);

  return React.useMemo(() => (
    <Popup
      position={titlePosition || 'bottom left'}
      trigger={triggerComponent}
      content={contentComponent}
      mouseEnterDelay={2000}
    />
  ), [
    contentComponent,
    triggerComponent,
    titlePosition,
  ]);
};
