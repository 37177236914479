// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import type { User } from '../../entities/User';

export type ApplicationSessionContextProps = {
  /** */
  user: User;
};

export const ApplicationSessionContext = React.createContext<ApplicationSessionContextProps>({
  user: null,
});
