// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Grid, Segment,
} from 'semantic-ui-react';

export type ApplicationLauncherMenuProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationLauncherMenuType = React.ComponentType<ApplicationLauncherMenuProps>;

export const ApplicationLauncherMenu: ApplicationLauncherMenuType = (
  props: ApplicationLauncherMenuProps,
) => {
  const {
    children,
  } = props;

  return React.useMemo(() => (
    <Grid as={Segment} celled="internally" relaxed="very" columns="equal" basic>
      {children}
    </Grid>
  ), [children]);
};
