// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type MenuIconProps = {
  active?: boolean;
};

export type MenuIconType = React.ComponentType<MenuIconProps>;

export const MenuIcon: MenuIconType = (props) => {
  const { active, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 512 512"
      fill="currentColor"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <defs><style>{'.menu-secondary { opacity: .4 }'}</style></defs>
      <path className="menu-secondary" d="M256 64c106 0 192 86 192 192s-86 192-192 192c-17.7 0-32 14.3-32 32s14.3 32 32 32c141.4 0 256-114.6 256-256S397.4 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
      <path
        className="menu-primary"
        style={{ transition: 'transform 0.2s ease-in-out', transform: active ? 'rotateY(180deg) translateX(140px)' : 'rotateY(0deg)', transformOrigin: 'center' }}
        d="M185.4 121.4c12.5-12.5 32.8-12.5 45.3 0l112 112c12.5 12.5 12.5 32.8 0 45.3l-112 112c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H242.7l-57.4-57.4c-12.5-12.5-12.5-32.8 0-45.3z"
      />
    </svg>
  );
};
