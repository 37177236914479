// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export * from './ApplicationContainer';

// eslint-disable-next-line no-restricted-exports
export { ApplicationContainer as default } from './ApplicationContainer';
