// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Popup } from 'semantic-ui-react';

import './ApplicationLauncherPopup.less';

import { ApplicationLauncherMenu } from './ApplicationLauncherMenu';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

const className = getApplicationClassName('launcher', 'popup');

export type ApplicationLauncherPopupProps = {
  /** Primary content. */
  children?: React.ReactNode;

  /** */
  code?: string;

  /** */
  trigger?: React.ReactNode;
};

export type ApplicationLauncherPopupType = React.ComponentType<ApplicationLauncherPopupProps>;

export const ApplicationLauncherPopup: ApplicationLauncherPopupType = (
  props: ApplicationLauncherPopupProps,
) => {
  const {
    children,
    trigger,
    code,
  } = props;

  const { open } = React.useContext(ApplicationLauncherContext);

  const [visible, setVisible] = React.useState(false);

  const handleClose = React.useCallback(() => {
    React.startTransition(() => {
      setVisible(false);
    });
  }, []);

  const handleOpen = React.useCallback(() => {
    React.startTransition(() => {
      setVisible(true);
    });
  }, []);

  React.useEffect(() => {
    if (!open) {
      React.startTransition(() => {
        setVisible(false);
      });
    }
  }, [open]);

  const content = React.useMemo(() => (
    <ApplicationLauncherMenu>
      {children}
    </ApplicationLauncherMenu>
  ), [children]);

  return React.useMemo(() => (
    <Popup
      className={getClassName(className, 'scale', { in: visible, out: !visible }, code)}
      trigger={trigger}
      onClose={handleClose}
      onOpen={handleOpen}
      on="click"
      open={visible}
      position="bottom left"
      basic
      flowing
      hoverable
    >
      {content}
    </Popup>
  ), [
    className,
    code,
    trigger,
    content,
    visible,
    content,
    handleClose,
    handleOpen,
  ]);
};
