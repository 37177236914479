// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationThemeContext, ApplicationThemeVariant } from '../ApplicationTheme';
import { ApplicationSessionContext } from '../ApplicationSession';
import { ApplicationNavigationContextProvider } from '../ApplicationNavigation';
import { ApplicationPageContextProvider } from './ApplicationPageContextProvider';

import { ApplicationContent } from '../ApplicationContent';
import { ApplicationFrame } from '../ApplicationFrame';

import { ApplicationLoader } from '../ApplicationLoader';

import { TenantSelector } from '../../entities/User';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationPageProps = { };

export type ApplicationPageType = React.ComponentType<ApplicationPageProps>;

export const ApplicationPage: ApplicationPageType = () => {
  const { setVariant } = React.useContext(ApplicationThemeContext);
  const { user } = React.useContext(ApplicationSessionContext);

  const headerRef = React.useRef<HTMLElement>();
  const footerRef = React.useRef<HTMLElement>();
  const contentRef = React.useRef<HTMLElement>();

  const frame = React.useMemo(() => (
    <ApplicationNavigationContextProvider>
      <ApplicationPageContextProvider>
        <ApplicationFrame
          header={<ApplicationContent scope="header" ref={headerRef} />}
          footer={<ApplicationContent scope="footer" ref={footerRef} />}
          content={<ApplicationContent scope="content" ref={contentRef} />}
        />
      </ApplicationPageContextProvider>
    </ApplicationNavigationContextProvider>
  ), []);

  const selector = React.useMemo(() => (
    <>
      <ApplicationLoader type="screen" />
      <TenantSelector show cancellable={false} />
    </>
  ), []);

  React.useEffect(() => {
    setVariant(
      (prevState) => user?.profile?.gamme?.toLowerCase() as ApplicationThemeVariant ?? prevState,
    );
  }, [user?.profile?.gamme]);

  return (
    !_.isEmpty(user?.profile)
      ? frame
      : selector
  );
};
