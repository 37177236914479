// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

export type ApplicationEnvironmentContextProps = 'development' | 'testing' | 'staging' | 'production';

// eslint-disable-next-line max-len
export const ApplicationEnvironmentContext = React.createContext<ApplicationEnvironmentContextProps>('development');
