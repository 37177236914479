// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { useUpdateEffect, useSessionStorage } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationFrameContext } from './ApplicationFrameContext';

import { useLocation } from '../../services/router';

export type ApplicationFrameContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationFrameContextProviderType = React.ComponentType<ApplicationFrameContextProviderProps>;

export const ApplicationFrameContextProvider: ApplicationFrameContextProviderType = (
  props: ApplicationFrameContextProviderProps,
) => {
  const { children } = props;

  const { pathname } = useLocation();

  const { Provider } = ApplicationFrameContext;

  const [launcherOpen, setLauncherOpen] = useSessionStorage('interaction.launcher.open', false);
  const [notificationOpen, setNotificationOpen] = useSessionStorage('interaction.notification.open', false);
  const [menuOpen, setMenuOpen] = useSessionStorage('interaction.menu.open', false);
  const [helpOpen, setHelpOpen] = useSessionStorage('interaction.help.open', false);

  const refPath = React.useRef(pathname);

  useUpdateEffect(() => {
    if (refPath.current === pathname) return;

    setMenuOpen(false);
    setLauncherOpen(false);
  }, [pathname]);

  return React.useMemo(() => (
    <Provider
      value={
              {
                help: {
                  open: helpOpen,
                  setOpen: setHelpOpen,
                },
                menu: {
                  open: menuOpen,
                  setOpen: setMenuOpen,
                },
                launcher: {
                  open: launcherOpen,
                  setOpen: setLauncherOpen,
                },
                notification: {
                  open: notificationOpen,
                  setOpen: setNotificationOpen,
                },
              }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    helpOpen,
    menuOpen,
    launcherOpen,
    notificationOpen,
    setHelpOpen,
    setMenuOpen,
    setLauncherOpen,
    setNotificationOpen,
  ]);
};
