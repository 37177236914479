// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Menu, Sidebar,
} from 'semantic-ui-react';

import './ApplicationLauncherList.less';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

import { DomainLauncherList } from '../../entities/Domain';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('launcher', 'list');

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationLauncherListProps = { };

export type ApplicationLauncherListType = React.ComponentType<ApplicationLauncherListProps>;

export const ApplicationLauncherList: ApplicationLauncherListType = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: ApplicationLauncherListProps,
) => {
  const { open, setOpen } = React.useContext(ApplicationLauncherContext);

  const handleHidden = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(false);
      });
    }
  }, [setOpen]);

  const handleShow = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(true);
      });
    }
  }, [setOpen]);

  const menu = React.useMemo(() => (
    <Menu
      secondary
      inverted
      borderless
      fluid
      stackable
    >
      <DomainLauncherList />
    </Menu>
  ), []);

  return React.useMemo(() => (
    <Sidebar
      className={className}
      as={Menu}
      animation="overlay"
      direction="top"
      visible={open}
      onHidden={handleHidden}
      onShow={handleShow}
      inverted
      borderless
    >
      {menu}
    </Sidebar>
  ), [
    open,
    menu,
    handleHidden,
    handleShow,
  ]);
};
