// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

import {
  ApplicationListItem, ApplicationListItemProps,
} from '../ApplicationList';

export type ApplicationLauncherMenuItemProps = ApplicationListItemProps;

export type ApplicationLauncherMenuItemType = React.ComponentType<ApplicationLauncherMenuItemProps>;

export const ApplicationLauncherMenuItem: ApplicationLauncherMenuItemType = (
  props: ApplicationLauncherMenuItemProps,
) => {
  const {
    className,
    name,
    actions,
    content,
    description,
    direction,
    disabled,
    header,
    link,
    path,
    offset,
    popup,
    placeholder,
    onClick,
    ...rest
  } = props;

  const { setOpen } = React.useContext(ApplicationLauncherContext);

  const handleClick = React.useCallback((e, d) => {
    onClick?.(e, d);

    React.startTransition(() => {
      setOpen(false);
    });
  }, [onClick]);

  return React.useMemo(() => (
    <ApplicationListItem
      className={className}
      name={name}
      actions={actions}
      content={content}
      description={description}
      direction={direction}
      disabled={disabled}
      header={header}
      link={link}
      path={path}
      offset={offset}
      popup={popup}
      placeholder={placeholder}
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  ), [
    className,
    name,
    actions,
    content,
    description,
    direction,
    disabled,
    header,
    link,
    path,
    offset,
    popup,
    placeholder,
    onClick,
    rest,
  ]);
};
