// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Sidebar, Segment, Menu, Icon, Container, Grid, Header, Button, Transition,
} from 'semantic-ui-react';

import './ApplicationNotification.less';

import * as i18n from '../../config/i18n';

import { ApplicationNotificationContextProvider } from './ApplicationNotificationContextProvider';

import { ApplicationSessionContext } from '../ApplicationSession';
import { ApplicationFrameContext } from '../ApplicationFrame';

import {
  NotificationList,
  NotificationModal,
  NotificationService,
} from '../../entities/Notification';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('notification');

export type ApplicationNotificationProps = {
  /** */
  pin?: boolean;

  /** */
  onPin?: () => void;
};

export type ApplicationNotificationType = React.ComponentType<ApplicationNotificationProps>;

export const ApplicationNotification: ApplicationNotificationType = (
  props: ApplicationNotificationProps,
) => {
  const { pin, onPin } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const { notification: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const [notificationOpen, setNotificationOpen] = React.useState(false);

  const handleShow = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(true);
      });
    }
  }, [setOpen]);

  const handleHide = React.useCallback(() => {
    if (!pin && !notificationOpen) {
      if (setOpen) {
        React.startTransition(() => {
          setOpen(false);
        });
      }
    }
  }, [pin, notificationOpen, setOpen]);

  const handlePin = React.useCallback(() => {
    onPin?.();
  }, [onPin]);

  const handleNotificationOpen = React.useCallback(() => {
    React.startTransition(() => {
      setNotificationOpen(true);
    });
  }, []);

  const handleNotificationClose = React.useCallback(() => {
    React.startTransition(() => {
      setNotificationOpen(false);
    });
  }, []);

  const handleClearNotifications = React.useCallback(() => {
    NotificationService.clearNotifications();
  }, []);

  const header = React.useMemo(() => (
    <Menu secondary>
      <Menu.Item data-testid="notification.pin" icon onClick={handlePin}>
        <Icon
          name="pin"
          color={!pin ? 'red' : 'black'}
          style={{ transform: `rotateZ(${pin ? 0 : 30}deg)` }}
        />
      </Menu.Item>
      <Menu.Item data-testid="notification.header">
        <Header>{i18n.notificationHeader}</Header>
      </Menu.Item>
      <Menu.Menu position="right">
        <Button
          data-testid="notification.trash"
          size="mini"
          icon="trash"
          content="Effacer tout"
          onClick={handleClearNotifications}
        />
      </Menu.Menu>
    </Menu>
  ), [
    pin,
    handlePin,
  ]);

  const CreateTaskIcon = React.useMemo(() => (
    <Icon.Group>
      <Icon name="tasks" />
      <Icon name="add" color="green" corner />
    </Icon.Group>
  ), []);

  return React.useMemo(() => (
    <ApplicationNotificationContextProvider>
      <Sidebar
        className={className}
        as={Segment}
        visible={open}
        animation={pin ? 'push' : 'overlay'}
        direction="right"
        width="wide"
        onShow={handleShow}
        onHide={handleHide}
      >
        <Container fluid>
          {header}
          <Container fluid>
            <Grid.Row stretched style={{ overflow: 'hidden' }}>
              <Transition mountOnShow unmountOnHide visible={open}>
                <NotificationList user={user} />
              </Transition>
            </Grid.Row>
            <Grid.Row verticalAlign="bottom">
              <Menu borderless>
                <NotificationModal
                  trigger={<Menu.Item data-testid="notification.create" icon={CreateTaskIcon} content={i18n.notificationCreate} />}
                  notification={{ type: 'tache' }}
                  onOpen={handleNotificationOpen}
                  onClose={handleNotificationClose}
                />
              </Menu>
            </Grid.Row>
          </Container>
        </Container>
      </Sidebar>
    </ApplicationNotificationContextProvider>
  ), [
    user,
    open,
    pin,
    header,
    handleShow,
    handleHide,
    handleNotificationOpen,
    handleNotificationClose,
  ]);
};
