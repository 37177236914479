// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Search, Dropdown, Ref,
} from 'semantic-ui-react';

import { useOnEvent } from '@jvs-group/jvs-mairistem-tools';

import './ApplicationSearch.less';

import * as i18n from '../../config/i18n';
import * as api from '../../config/api';

import { ApplicationSearchLayout } from './ApplicationSearchLayout';

import { privateApi } from '../../api';
import { buildEndPoint } from '../../api/buildEndPoint';

import { getApplicationClassName } from '../utils/className';
import { FeatureService } from '../../entities/Feature';
import { DomainService } from '../../entities/Domain';

const className = getApplicationClassName('search');

export declare type ApplicationSearchProps = {
  /** */
  category?: ((result: unknown) => string) | string;
};

export type ApplicationSearchType = React.ComponentType<ApplicationSearchProps>;

export const ApplicationSearch: ApplicationSearchType = (
  props: ApplicationSearchProps,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { category = 'type' } = props;

  const [showNoResults, setShowNoResults] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [filter, setFilter] = React.useState([]);

  const container = React.useRef<HTMLElement>();
  const input = React.useRef<HTMLInputElement>();

  const [domaines] = DomainService.queryDomains();
  const [features] = FeatureService.queryFeaturesByQuery(value);

  const search = React.useCallback(_.debounce((v: string) => {
    setLoading(true);
    setShowNoResults(false);

    privateApi.get(buildEndPoint(api.searchEndPoint, { query: v, module: filter }))
      .then((data: []) => {
        setResults(_.reduce(data, (categories, items, type) => [
          ...categories,
          {
            name: _.upperFirst(type),
            results: items.map((item) => ({
              id: item.id,
              libelle: item.libelle,
              description: item.description,
              type: item.type,
              adresse: item.adresse,
              module: { identifiant: item.id_produit },
            })),
          },
        ], []));
      })
      .finally(() => {
        setLoading(false);
        setShowNoResults(true);

        if (features.length) {
          setResults((prevState) => [
            ...prevState,
            {
              name: 'Fonctionnalités',
              results: features.map((feature) => ({
                ...feature,
                adresse: feature.adresse.replace(':identifiant', '_'),
              }
              )),
            },
          ]);
        }
      });
  }, 500), [features]);

  const handleSearchChange = React.useCallback((
    e: React.SyntheticEvent,
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
    { value: input }: { value: string },
  ) => {
    setValue(input);
  }, []);

  const handleSelectionChange = React.useCallback((
    e: React.SyntheticEvent,
    data,
  ) => {
    // eslint-disable-next-line no-console
    console.log('selection change', data);
  }, []);

  const handleFilterChange = React.useCallback((
    e: React.SyntheticEvent,
    data,
  ) => {
    // eslint-disable-next-line no-console
    console.log('filter change', data);

    setFilter([_.last(data.value)]);
  }, []);

  const handleResultSelect = React.useCallback((
    e: React.SyntheticEvent,
    data,
  ) => {
    // eslint-disable-next-line no-console
    console.log('result select', data);

    setValue('');
  }, []);

  const handleKeyDown = React.useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }

    if (event.keyCode === 27) {
      event.preventDefault();
    }
  }, []);

  const handleFocus = React.useCallback(() => {
    setShowNoResults(false);
  }, [value]);

  const handleDocumentKeyDown = React.useCallback((event) => {
    if (event.code === 'KeyF' && event.ctrlKey) {
      input.current?.focus();

      event.preventDefault();
    }
  }, []);

  React.useEffect(() => {
    setResults([]);

    if (value.length >= 3) {
      search(value);
    }
  }, [
    search,
    value,
  ]);

  useOnEvent(document, 'keydown', handleDocumentKeyDown);

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  const categoryRenderer = React.useCallback(({ name, results }) => ({
    name, module: _.first(results).module,
  }), []);

  const categoryLayoutRenderer = React.useCallback(({
    categoryContent,
    resultsContent,
  }) => (
    <ApplicationSearchLayout
      category={categoryContent}
      results={resultsContent}
      query={value}
    />
  ), [value]);

  const placeholder = React.useMemo(() => (
    <span className="placeholder-shortcut">
      <kbd>CTRL</kbd>
      +
      <kbd>F</kbd>
    </span>
  ), []);

  return React.useMemo(() => (
    <Ref innerRef={container}>
      <Search
        data-testid="search"
        className={className}
        placeholder={i18n.searchPlaceholder}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        categoryRenderer={categoryRenderer}
        categoryLayoutRenderer={categoryLayoutRenderer}
        noResultsMessage={i18n.searchNoResultMessage}
        noResultsDescription={i18n.searchNoResultDescription}
        onSearchChange={handleSearchChange}
        onSelectionChange={handleSelectionChange}
        onResultSelect={handleResultSelect}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        loading={loading}
        results={results}
        value={value}
        showNoResults={showNoResults}
        minCharacters={3}
        input={{
          ref: input,
          transparent: true,
          inverted: true,
          label: (
            <>
              {placeholder}
              <Dropdown
                icon={null}
                value={filter}
                options={domaines?.map((domaine) => ({
                  key: domaine.identifiant,
                  text: domaine.libelle,
                  value: domaine.identifiant,
                }))}
                multiple
                closeOnChange
                onChange={handleFilterChange}
              />
            </>
          ),
          labelPosition: 'left',
          icon: 'filter',
          iconPosition: 'left',
        }}
        fluid
        category
      />
    </Ref>
  ), [
    placeholder,
    value,
    filter,
    results,
    domaines,
    loading,
    showNoResults,
    handleFocus,
    handleResultSelect,
    handleSearchChange,
    handleFilterChange,
  ]);
};
