// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

export const loaderMessage = 'Chargement en cours ...';

export const loaderRandomMessage = () => _.sample([
  'Chargement en cours ...',
  'La patience est la vertu du juste. --- Alphonse de Lamartine',
  'La patience est la vertu des sages. --- Proverbe français',
  'La patience est le courage de la vertu. --- Michel de Montaigne',
  'La patience est la plus grande des prières. --- Bouddha',
  'La patience est l\'art de trouver autre chose à faire. --- Groucho Marx',
  'La patience est la clé du bien-être. --- Mahomet',
  'La patience est le sourire de l\'âme. --- Philippe Sollers',
  'La patience n\'a l\'air de rien, c\'est tout de même une énergie. --- Jean Cocteau',
  'La patience adoucit tout mal sans remède. --- Horace',
  'La patience est un ingrédient indispensable du génie. --- Benjamin Disraeli',
  'La patience est un élément clé de la réussite. --- Bill Gates',
  'La patience et la volonté font des miracles. --- Laure Conan',
  'La patience a beaucoup plus de pouvoir que la force. --- Plutarque',
  'La patience est un arbre dont la racine est amère, et dont les fruits sont très doux. --- Proverbe persan',
  'La patience joue contre les offenses exactement le même rôle que les vêtements contre le froid. --- Léonard De Vinci',
  'La patience est la force des faibles, l\'impatience est la faiblesse des forts. --- Immanuel Kant',
  'La patience est une fleur qui ne pousse pas dans tous les jardins. --- Proverbe chinois',
  'La patience est la clé de l\'évolution, de la force intérieure, de la persévérance et de la sagesse. --- Bouddha',
  'La patience rend tolérable ce qu\'on ne peut empêcher. --- Horace',
  'La patience est une vertu, et j\'apprends la patience. C\'est une leçon difficile. --- Elon Musk',
  'La patience, c\'est comme le chocolat... On n\'en a jamais assez ! --- Anonyme',
  'La patience est une vertu qui s\'acquiert avec de la patience. --- Proverbe chinois',
  'La patience et la prévoyance sont les deux qualités les plus importantes dans les affaires. --- Henry Ford',
  'Les meilleures choses ont besoin de patience. --- Proverbe français',
  'Le temps est la meilleur des critiques; et la patience le meilleur des professeurs. --- Jean-Jacques Rousseau',
  'La réussite est liée à la patience mais elle dépend également de beaucoup de bonne volonté. --- Proverbe chinois',
  'Le découragement est beaucoup plus douloureux que la patience. --- Proverbe chinois',
  'Le meilleur remède pour tous les problèmes, c\'est la patience. --- Plaute',
  'Le génie, c\'est souvent une longue patience de la part des autres. --- Boris Vian',
  'L\'Homme sans patience, c\'est comme une lampe sans huile. --- Alfred De Musset',
  'Patience ! Avec le temps, l\'herbe devient du lait. --- Proverbe malgache',
  'Patience et tolérance ne sont pas signes de faiblesse, mais des preuves de force. --- Dalaï Lama',
  'Soyez patient avec tout le monde, mais surtout avec vous-mêmes. --- Saint François de Sales',
  'Patience et longueur de temps, Font plus que force ni que rage. --- Jean de La Fontaine',
  'Qui veut voyager loin ménage sa monture. --- Jean Racine',
  'À qui sait attendre, le temps ouvre les portes. --- Ibn Arabi',
  'Ce qui manque, ce n\'est pas le temps, c\'est la patience. --- Sénèque',
  'Sans patience, il n`\'y a pas de sagesse. --- Mocharrafoddin Saadi',
  'Un moment de patience dans un moment de colère empêche mille moments de regret. --- Ali Ibn Abi Talib',
]);
