// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Container, Button,
} from 'semantic-ui-react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationHelpFeedbackProps = { };

export type ApplicationHelpFeedbackType = React.ComponentType<ApplicationHelpFeedbackProps>;

export const ApplicationHelpFeedback: ApplicationHelpFeedbackType = () => React.useMemo(() => (
  <Container className="feedback" textAlign="center">
    <Button icon="comments" content="Envoyer un commentaire" fluid />
  </Container>
), []);
