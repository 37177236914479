// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Container, Dimmer, Message, Icon,
} from 'semantic-ui-react';

import Sentry from '../../services/sentry';

import * as i18n from '../../config/i18n';

import './ApplicationError.less';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('error');

type ApplicationErrorProps = {
  /** Primary content. */
  children: React.ReactNode;

  /** */
  dependencies: unknown[];

  /** */
  scope: 'content' | 'header' | 'footer' | 'help';
};

type ApplicationErrorState = {
  /** */
  hasError: boolean;

  /** */
  error?: Error;

  /** */
  info?: React.ErrorInfo;
};

// eslint-disable-next-line max-len
export class ApplicationError extends React.Component<ApplicationErrorProps, ApplicationErrorState> {
  public constructor(props: ApplicationErrorProps) {
    super(props);

    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidUpdate(prevProps, prevState): void {
    const { dependencies } = this.props;
    const { error } = this.state;

    if (
      error !== null
      && prevState.error !== null
      && !_.isEqual(prevProps.dependencies, dependencies)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        error: null,
        hasError: false,
      });
    }
  }

  public componentDidCatch(error: Error): void {
    this.setState({ error });

    Sentry?.captureException(error);
  }

  public render() {
    const { children, scope } = this.props;
    const {
      hasError, error,
    } = this.state;

    if (hasError) {
      if (scope === 'content') {
        return (
          <Dimmer.Dimmable className={className} dimmed blurring>
            <Dimmer active inverted>
              <Container>
                <Message info icon>
                  <Icon name="searchengin" />
                  <Message.Content>
                    <Message.Header>
                      {i18n.errorHeader}
                    </Message.Header>
                    <p>
                      <span>{i18n.errorMessage}</span>
                    </p>
                    <br />
                    <p>
                      <i><span>{error?.message}</span></i>
                    </p>
                  </Message.Content>
                </Message>
              </Container>
            </Dimmer>
          </Dimmer.Dimmable>
        );
      }

      return null;
    }

    return children;
  }
}
