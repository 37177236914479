// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';
import path from 'path';

import './ApplicationSearchLayout.less';

import { ApplicationSearchCategory } from './ApplicationSearchCategory';
import { ApplicationSearchResult } from './ApplicationSearchResult';
import { ApplicationList } from '../ApplicationList';

import { getApplicationClassName, getClassName } from '../utils/className';

import { Module, ModuleService } from '../../entities/Module';
import { DomainService } from '../../entities/Domain';

const className = getApplicationClassName('search', 'layout');

export declare type ApplicationSearchLayoutProps = {
  /** */
  category?: { name: string, module: Module };

  /** */
  results?: unknown[];

  /** */
  query?: string;
};

export type ApplicationSearchLayoutType = React.ComponentType<ApplicationSearchLayoutProps>;

export const ApplicationSearchLayout: ApplicationSearchLayoutType = (
  props: ApplicationSearchLayoutProps,
) => {
  const {
    category,
    results,
    query,
  } = props;

  const handleCategoryClick = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const [module] = ModuleService.queryModule(category?.module?.identifiant);
  const [domain] = DomainService.queryDomain(module?.domaine?.identifiant);

  return React.useMemo(() => (
    <>
      <ApplicationSearchCategory
        name={category?.name}
        code={domain?.code}
        count={_.size(results)}
        onClick={handleCategoryClick}
      />
      <ApplicationList
        className={getClassName(className, domain?.code)}
        items={_.map(
          results,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (result) => React.cloneElement(result, {
            as: ApplicationSearchResult,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            key: result.props.adresse,
            path: path.join(module.adresse, result.props.adresse),
            query,
          }),
        )}
        virtualized
      />
    </>
  ), [
    category,
    module?.adresse,
    domain?.code,
    results,
  ]);
};
