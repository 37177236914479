// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Sidebar, Segment, Menu, Icon, Container, Header,
} from 'semantic-ui-react';

import './ApplicationHelp.less';

import * as i18n from '../../config/i18n';

import { ApplicationHelpContextProvider } from './ApplicationHelpContextProvider';

import { ApplicationNavigationContext } from '../ApplicationNavigation';
import { ApplicationFrameContext } from '../ApplicationFrame';

import { ApplicationError } from '../ApplicationError';
import { ApplicationModule } from '../ApplicationModule';

import { ApplicationHelpFeedback } from './ApplicationHelpFeedback';
import { ApplicationHelpModule } from './ApplicationHelpModule';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('help');

export type ApplicationHelpProps = {
  /** */
  pin?: boolean;

  /** */
  onPin?: () => void;
};

export type ApplicationHelpType = React.ComponentType<ApplicationHelpProps>;

export const ApplicationHelp: ApplicationHelpType = (
  props: ApplicationHelpProps,
) => {
  const {
    pin,
    onPin,
  } = props;

  const container = React.useRef<HTMLElement>();

  const { module, feature } = React.useContext(ApplicationNavigationContext);

  const { help: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const handleShow = React.useCallback(() => {
    if (setOpen) {
      setOpen(true);
    }
  }, [setOpen]);

  const handleHide = React.useCallback(() => {
    if (!pin) {
      if (setOpen) {
        setOpen(false);
      }
    }
  }, [pin, setOpen]);

  const handlePin = React.useCallback(() => {
    onPin?.();
  }, [onPin]);

  const header = React.useMemo(() => (
    <Menu secondary>
      <Menu.Item data-testid="help.pin" icon onClick={handlePin}>
        <Icon
          name="pin"
          color={!pin ? 'red' : 'black'}
          style={{ transform: `rotateZ(${pin ? 0 : 30}deg)` }}
        />
      </Menu.Item>
      <Menu.Item data-testid="help.header">
        <Header>{i18n.helpHeader}</Header>
      </Menu.Item>
    </Menu>
  ), [
    pin,
    handlePin,
  ]);

  const content = React.useMemo(() => (
    <ApplicationError scope="help" dependencies={[feature]}>
      <Container fluid style={{ ...(feature?.integre && { display: 'none' }) }}>
        <main ref={container} className={module?.code} />
      </Container>
      <ApplicationModule
        scope="help"
        container={container}
        name={feature?.components?.help}
        code={module?.code}
        hideSidebar={handleHide}
        showSidebar={handleShow}
      />
    </ApplicationError>
  ), [
    module,
    feature,
    handleHide,
    handleShow,
  ]);

  return React.useMemo(() => (
    <ApplicationHelpContextProvider>
      <Sidebar
        className={className}
        as={Segment}
        visible={open}
        animation={pin ? 'push' : 'overlay'}
        direction="right"
        width="wide"
        onShow={handleShow}
        onHide={handleHide}
      >
        <Container fluid>
          {header}
          <Container fluid>
            <Container className="stretched">
              {content}
            </Container>
            <Container className="fitted">
              <ApplicationHelpFeedback />
              <ApplicationHelpModule />
            </Container>
          </Container>
        </Container>
      </Sidebar>
    </ApplicationHelpContextProvider>
  ), [
    content,
    open,
    pin,
    header,
    handleShow,
    handleHide,
  ]);
};
