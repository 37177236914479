// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import { publicApi } from '../api';

export const usePublicApi = <T>(
  uri: string,
  callback: (data: any) => T = (data) => data
): [T, Error | null, boolean] => {
    const [data, setData] = React.useState<T>(null as T);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);

        publicApi.get(uri)
            .then((data) => {
                setData(callback(data));
            })
            .catch((error: Error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [uri]);

    return [data, error, loading];
}
