// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import * as Redux from 'redux';

import * as History from 'history';

import '@jvs-group/jvs-mairistem-styles';

import Application from './components/Application';

import {
  reducer, enhancer,
} from './entities';

import * as Sentry from './services/sentry';

import {
  disableReactDevTools,
  disableReduxDevTools,
} from './utils/devTools';

const app = document.getElementById('app');

const store = Redux.createStore(reducer, enhancer);
const history = History.createBrowserHistory({ window });

const root = ReactDOM.createRoot(app);

root.render(
  // <React.StrictMode>
  <Application store={store} history={history} />,
  // </React.StrictMode>,
);

Sentry.initialize(store, history);

if (process.env.NODE_ENV === 'production' && process.env.APP_ENV === 'staging') {
  disableReactDevTools();
  disableReduxDevTools();
}
