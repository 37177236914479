// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const moduleEndPoint = '/api/v1/utilisateurs/produits/modules/:identifiant';
export const modulesEndPoint = '/api/v1/utilisateurs/produits/modules';
export const modulesByDomainEndPoint = '/api/v1/utilisateurs/produits/domaines/:identifiantDomaine/modules';
