// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Icon,
  Image,
  Menu,
  Popup,
} from 'semantic-ui-react';

import * as i18n from '../../config/i18n';

import './ApplicationLauncherBar.less';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

import { ApplicationNavigationContext } from '../ApplicationNavigation';
import { ApplicationFrameContext } from '../ApplicationFrame';
import { ApplicationPageContext } from '../ApplicationPage';
import { ApplicationSessionContext } from '../ApplicationSession';

import { ApplicationSearch } from '../ApplicationSearch';

import {
  ApplicationBreadcrumb,
  ApplicationBreadcrumbSection,
  ApplicationBreadcrumbSeparator,
} from '../ApplicationBreadcrumb';

import { DomainDataPrefetch } from '../../entities/Domain/components/DomainDataPrefetch';

import { UserProfile } from '../../entities/User';
import { NotificationIcon } from '../../entities/Notification';

import { LauncherIcon } from '../../assets/icons/Launcher';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

const className = getApplicationClassName('launcher', 'bar');

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApplicationLauncherBarProps = { };

export type ApplicationLauncherBarType = React.ComponentType<ApplicationLauncherBarProps>;

export const ApplicationLauncherBar: ApplicationLauncherBarType = () => {
  const { open, setOpen } = React.useContext(ApplicationLauncherContext);

  const {
    notification: { open: openNotification, setOpen: setOpenNotification },
    help: { open: openHelp, setOpen: setOpenHelp },
  } = React.useContext(ApplicationFrameContext);

  const { user } = React.useContext(ApplicationSessionContext);
  const {
    domain, module, feature,
  } = React.useContext(ApplicationNavigationContext);

  const { manifest } = React.useContext(ApplicationPageContext);
  const { breadcrumbRenderer } = manifest || {};

  const handleClick = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen((o) => !o);
      });
    }
  }, []);

  const handleOpenNotification = React.useCallback(() => {
    if (setOpenNotification) {
      React.startTransition(() => {
        setOpenNotification((o) => !o);
      });
    }
  }, [setOpenNotification]);

  const handleOpenHelp = React.useCallback(() => {
    if (setOpenHelp) {
      React.startTransition(() => {
        setOpenHelp((o) => !o);
      });
    }
  }, [setOpenHelp]);

  const handleOpenProfile = React.useCallback(() => {
    React.startTransition(() => {
      setOpen(false);
    });
  }, []);

  React.useEffect(() => {
    if (openNotification || openHelp) {
      React.startTransition(() => {
        setOpen(false);
      });
    }
  }, [openNotification, openHelp]);

  const brand = React.useMemo(() => (
    <DomainDataPrefetch>
      <Menu.Item data-testid="brand" aria-label="brand" id="brand" onClick={handleClick}>
        <LauncherIcon />
        <Image className={getClassName('logo', 'inverted')} spaced="right" aria-label="brand" />
      </Menu.Item>
    </DomainDataPrefetch>
  ), [
    handleClick,
  ]);

  const nativeBreadcrumb = React.useMemo(() => (
    <ApplicationBreadcrumb>
      <ApplicationBreadcrumbSection key="domain" libelle={domain?.libelle} />
      <ApplicationBreadcrumbSeparator />
      <ApplicationBreadcrumbSection key="module" libelle={module?.libelle} />
      <ApplicationBreadcrumbSeparator />
      <ApplicationBreadcrumbSection key="feature" libelle={feature?.libelle} />
    </ApplicationBreadcrumb>
  ), [
    domain?.libelle,
    module?.libelle,
    feature?.libelle,
  ]);

  const customBreadcrumb = React.useMemo(() => {
    if (breadcrumbRenderer) {
      return breadcrumbRenderer(
        { domain, module, feature },
        {
          BreadcrumbContainer: ApplicationBreadcrumb,
          BreadcrumbSeparator: ApplicationBreadcrumbSeparator,
          BreadcrumbSection: ApplicationBreadcrumbSection,
        },
      );
    }

    return null;
  }, [
    breadcrumbRenderer,
    domain,
    module,
    feature,
  ]);

  // Le développeur peut définir un rendu personnalisé pour le fil d'ariane
  // Si c'est le cas, on récupère son render pour l'afficher à la place du notre.
  const breadcrumb = React.useMemo(() => {
    if (breadcrumbRenderer) {
      return customBreadcrumb;
    }

    return nativeBreadcrumb;
  }, [
    breadcrumbRenderer,
    customBreadcrumb,
    nativeBreadcrumb,
  ]);

  const notification = React.useMemo(() => (
    <Popup
      on="hover"
      position="bottom center"
      mouseEnterDelay={500}
      trigger={(
        <Menu.Item data-testid="notification" id="notification" icon active={openNotification} onClick={handleOpenNotification}>
          <NotificationIcon open={openNotification || open} user={user} />
        </Menu.Item>
      )}
      disabled={openNotification}
    >
      {i18n.notificationHeader}
    </Popup>
  ), [
    user,
    open,
    openNotification,
    handleOpenNotification,
  ]);

  const help = React.useMemo(() => (
    <Popup
      on="hover"
      position="bottom center"
      mouseEnterDelay={500}
      trigger={(
        <Menu.Item data-testid="help" id="help" icon active={openHelp} onClick={handleOpenHelp}>
          <Icon name="help" size="large" />
        </Menu.Item>
      )}
      disabled={openHelp}
    >
      {i18n.helpHeader}
    </Popup>
  ), [
    open,
    openHelp,
    handleOpenHelp,
  ]);

  const options = React.useMemo(() => (
    <Menu.Menu className="options" position="right">
      <ApplicationSearch />
      {notification}
      {help}
      <UserProfile onOpen={handleOpenProfile} />
    </Menu.Menu>
  ), [
    help,
    notification,
  ]);

  return React.useMemo(() => (
    <Menu
      className={className}
      fitted="vertically"
      borderless
      inverted
      compact
      fluid
      // UI: primary
    >
      {brand}
      {breadcrumb}
      {options}
    </Menu>
  ), [
    brand,
    breadcrumb,
    options,
  ]);
};
