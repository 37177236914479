// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Container } from 'semantic-ui-react';

import './ApplicationContent.less';

import { ApplicationError } from '../ApplicationError';
import { ApplicationModule } from '../ApplicationModule';

import { ApplicationNavigationContext } from '../ApplicationNavigation';

import { ApplicationView } from '../ApplicationView/ApplicationView';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

export type ApplicationContentProps = {
  /** */
  scope: 'content' | 'header' | 'footer';

  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  ref?: React.Ref<HTMLElement>;
};

export type ApplicationContentType = React.ComponentType<ApplicationContentProps>;

export const ApplicationContent = React.forwardRef((
  props: ApplicationContentProps,
  ref: React.MutableRefObject<HTMLElement>,
) => {
  const { scope } = props;

  const className = [
    getApplicationClassName('content'),
    getApplicationClassName('content', scope),
  ];

  const container = React.useRef<HTMLElement>();

  const { module, feature } = React.useContext(ApplicationNavigationContext);

  React.useImperativeHandle(ref, () => container.current);

  const contentView = React.useMemo(() => (
    <ApplicationView
      key={feature?.identifiant}
      scope={scope}
      component={feature?.components?.[scope]}
      // container={container}
    />
  ), [
    scope,
    feature,
  ]);

  const contentModule = React.useMemo(() => (
    <ApplicationModule
      key={feature?.identifiant}
      code={module?.code}
      scope={scope}
      name={feature?.components?.[scope]}
      container={container}
    />
  ), [
    scope,
    feature,
    module,
  ]);

  return (
    <Container className={getClassName(className)} fluid>
      <ApplicationError key={scope} scope={scope} dependencies={[feature]}>
        <Container fluid style={{ ...(feature?.integre && { display: 'none' }) }}>
          <main ref={container} className={module?.code} />
        </Container>
        {
            feature?.integre
              ? contentView
              : contentModule
        }
      </ApplicationError>
    </Container>
  );
});
