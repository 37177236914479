// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const notificationHeader = 'Notifications';
export const notificationEmpty = 'Aucune notification';

export const notificationClose = 'Effacer';
export const notificationDone = 'Fait';
export const notificationExpand = 'Développer';
export const notificationReduce = 'Réduire';

export const notificationCreate = 'Créer une tâche';

export const notificationSave = 'Enregistrer';
export const notificationCancel = 'Annuler';

export const notificationDeleting = 'Suppression en cours...';
