// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type LauncherIconProps = { };

export type LauncherIconType = React.ComponentType<LauncherIconProps>;

export const LauncherIcon: LauncherIconType = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="6" height="6" fill="currentColor" />
    <rect x="8" width="6" height="6" fill="currentColor" />
    <rect x="16" width="6" height="6" fill="currentColor" />
    <rect y="8" width="6" height="6" fill="currentColor" />
    <rect x="8" y="8" width="6" height="6" fill="currentColor" />
    <rect x="16" y="8" width="6" height="6" fill="currentColor" />
    <rect y="16" width="6" height="6" fill="currentColor" />
    <rect x="8" y="16" width="6" height="6" fill="currentColor" />
    <rect x="16" y="16" width="6" height="6" fill="currentColor" />
  </svg>
);
