// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type MenuBracketIconProps = {
  active?: boolean;
};

export type MenuBracketIconType = React.ComponentType<MenuBracketIconProps>;

export const MenuBracketIcon: MenuBracketIconType = (props) => {
  const { active, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.18em"
      height="1em"
      viewBox="0 0 320 512"
      fill="currentColor"
      style={{ transition: 'transform 0.2s ease-in-out', transform: active ? 'rotateZ(-90deg)' : 'rotateZ(0deg)' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <defs><style>{'.menu-bracket-secondary { opacity: .8 }'}</style></defs>
      <path className="menu-bracket-secondary" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
      <path className="menu-bracket-primary" d="" />
    </svg>
  );
};
