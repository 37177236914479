// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import { useLocalStorage } from '@jvs-group/jvs-mairistem-tools';

export const useTheme = <T>(
  key: string,
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [theme, setTheme] = useLocalStorage<T>(`theme.${key}`, initialState);

  // const handleStorageChange = React.useCallback((e) => {
  //   if(e.key === `theme.${key}`) {
  //     setTheme(() => e.newValue);
  //   }
  // }, [key])

  // React.useEffect(() => {
  //   window.addEventListener('storage', handleStorageChange);
    
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   }
  // }, [handleStorageChange])

  return [theme, setTheme];
};
