// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationFrameContextProvider } from './ApplicationFrameContextProvider';

import { ApplicationFrameMenu } from './ApplicationFrameMenu';
import { ApplicationFrameHelp } from './ApplicationFrameHelp';
import { ApplicationFrameNotification } from './ApplicationFrameNotification';

import { ApplicationHeader } from '../ApplicationHeader';
import { ApplicationFooter } from '../ApplicationFooter';

import { ApplicationLauncher } from '../ApplicationLauncher';

export type ApplicationFrameProps = {
  /** */
  content?: React.ReactNode;

  /** */
  header?: React.ReactNode;

  /** */
  footer?: React.ReactNode;
};

export type ApplicationFrameType = React.ComponentType<ApplicationFrameProps>;

export const ApplicationFrame: ApplicationFrameType = (
  props: ApplicationFrameProps,
) => {
  const {
    content,
    header,
    footer,
  } = props;

  return React.useMemo(() => (
    <ApplicationFrameContextProvider>
      <ApplicationLauncher>
        <ApplicationFrameNotification>
          <ApplicationFrameHelp>
            <ApplicationFrameMenu>
              <div style={{
                display: 'flex', flexDirection: 'column', height: '100%', width: '100%',
              }}
              >
                <ApplicationHeader>
                  {header}
                </ApplicationHeader>
                {content}
                <ApplicationFooter>
                  {footer}
                </ApplicationFooter>
              </div>
            </ApplicationFrameMenu>
          </ApplicationFrameHelp>
        </ApplicationFrameNotification>
      </ApplicationLauncher>
    </ApplicationFrameContextProvider>
  ), [
    content,
    header,
    footer,
  ]);
};
