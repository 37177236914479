// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { CSSTransition } from 'react-transition-group';

import {
  List, Popup,
  Ref, SemanticICONS,
} from 'semantic-ui-react';

import { useConst } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationPageContext } from '../ApplicationPage';

import { Link } from '../../services/router';

import './ApplicationMenuActionItem.less';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('menu', 'action', 'item');

export type ApplicationMenuActionItemProps = {
  identifiant: string | number;
  // eslint-disable-next-line react/require-default-props
  icon?: string;
  title: string;
  // eslint-disable-next-line react/require-default-props
  path?: string;
  // eslint-disable-next-line react/require-default-props
  onClick?: (
    action: { module: { routes: Array<{ code: string, name: string, path: string }> } }
  ) => void;

  // eslint-disable-next-line react/require-default-props
  open?: boolean;
  // eslint-disable-next-line react/require-default-props
  delay?: number;
};

export type ApplicationMenuActionItemType = React.ComponentType<ApplicationMenuActionItemProps>;

export const ApplicationMenuActionItem: ApplicationMenuActionItemType = (
  props: ApplicationMenuActionItemProps,
) => {
  const {
    identifiant,
    icon,
    title,
    path,
    onClick,
    open,
    delay = 0,
  } = props;

  const innerRef = React.useRef();

  const [inView, setInView] = React.useState(false);

  const { manifest } = React.useContext(ApplicationPageContext);

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  const routes = useConst(manifest?.menus?.map(({ code, name, path }) => ({ code, name, path })));

  const handleClick = React.useCallback(() => {
    onClick?.({ module: { routes } });
  }, [onClick]);

  React.useEffect(() => {
    React.startTransition(() => {
      setInView(open);
    });
  }, [open]);

  return (
    <CSSTransition
      key={identifiant}
      nodeRef={innerRef}
      in={inView}
      timeout={600}
      classNames="application-menu-action-item-transition"
    >
      <Ref innerRef={innerRef}>
        <Popup
          position="top center"
          size="mini"
          inverted
          trigger={(
            <List.Item
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(path && {
                as: Link,
                to: path,
              })}
              className={className}
              style={{ transitionDelay: `${delay}ms` }}
              onClick={onClick && handleClick}
            >
              <List.Icon name={(icon as SemanticICONS)} circular />
            </List.Item>
        )}
        >
          {title}
        </Popup>
      </Ref>
    </CSSTransition>
  );
};
