import React from 'react';
import { Breadcrumb, Menu } from 'semantic-ui-react';

type ApplicationBreadcrumbProps = {
  children: React.ReactNode,
};

const ApplicationBreadcrumb = (props: ApplicationBreadcrumbProps) => {
  const {
    children,
  } = props;

  return (
    <Menu.Item id="breadcrumb" header>
      <Breadcrumb>
        {children}
      </Breadcrumb>
    </Menu.Item>
  );
};

export default ApplicationBreadcrumb;
