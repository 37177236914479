// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const tenantsEndPoint = '/api/tenants';
export const tenantEndPoint = '/api/tenants/:tenant';
