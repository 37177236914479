// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Sidebar } from 'semantic-ui-react';

import { ApplicationLauncherContextProvider } from './ApplicationLauncherContextProvider';

import { ApplicationFrameContext } from '../ApplicationFrame';

import { ApplicationLauncherBar } from './ApplicationLauncherBar';
import { ApplicationLauncherList } from './ApplicationLauncherList';

export type ApplicationLauncherProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationLauncherType = React.ComponentType<ApplicationLauncherProps>;

export const ApplicationLauncher: ApplicationLauncherType = (
  props: ApplicationLauncherProps,
) => {
  const { children } = props;

  const { launcher: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const handleClick = React.useCallback(() => {
    if (setOpen) {
      React.startTransition(() => {
        setOpen(false);
      });
    }
  }, [setOpen]);

  return React.useMemo(() => (
    <ApplicationLauncherContextProvider>
      <ApplicationLauncherBar />
      <Sidebar.Pushable>
        <ApplicationLauncherList />
        <Sidebar.Pusher dimmed={open} onClick={handleClick}>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </ApplicationLauncherContextProvider>
  ), [
    open,
    children,
  ]);
};
