// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const navigationBack = 'Retour';

export const navigationOpenTab = 'Ouvrir dans un nouvel onglet';
