// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/browser';

import { isProduction } from '@jvs-group/jvs-mairistem-tools';

import pkg from '../../package.json';

export const initialize = (store, history) => {
  if (isProduction()) {
    Sentry.init({
      dsn: 'https://6b1786b6ccf8409d8b747300820e4c4f@sentry.jvs-mairistem.dev/3',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new CaptureConsole(),
      ],
      tracesSampleRate: 1.0,
      release: `${pkg.name.replace(/^@[\w-]+(\.)?[\w-]+\//, '')}@${pkg.version}`,
      environment: process.env.APP_ENV ?? 'production',
    });

    store.subscribe(() => {
      const { user, context } = store.getState();

      Sentry.setUser(
        user
          ? {
            id: user.utilisateur,
            email: user.email,
            username: `${user.prenom} ${user.nom}`,
          }
          : null,
      );
      Sentry.setContext(
        'tenant',
        !_.isEmpty(context)
          ? context.tenant
          : null,
      );
      Sentry.setContext(
        'database',
        !_.isEmpty(context)
          ? context.database
          : null,
      );
    });
  }
};

export default isProduction() ? Sentry : null;
