// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Icon } from 'semantic-ui-react';

import * as i18n from '../../config/i18n';

import { ApplicationWindow } from '../ApplicationWindow';
import { ApplicationListItem } from '../ApplicationList';

import { highlighter } from './utils/highlighter';

export declare type ApplicationSearchResultProps = {
  /** */
  libelle: string;

  /** */
  description: string;

  /** */
  path: string;

  /** */
  query: string;

  [key: string]: unknown;
};

export type ApplicationSearchResultType = React.ComponentType<ApplicationSearchResultProps>;

export const ApplicationSearchResult: ApplicationSearchResultType = (
  props: ApplicationSearchResultProps,
) => {
  const {
    libelle,
    description,
    path,
    query,
  } = props;

  const IconOpen = React.useMemo(() => (
    <Icon name="share square" link />
  ), []);

  const OpenAction = React.useMemo(() => (
    {
      icon: IconOpen,
      title: i18n.navigationOpenTab,
    }
  ), [IconOpen]);

  const highlightChunks = highlighter(libelle, query);

  const content = React.useMemo(() => (
    <span className="highlight">
      {highlightChunks.map(({ chunk, highlighted }) => (highlighted
        ? (
          <mark key={`mark-${chunk}`}>{chunk}</mark>
        ) : (
          <span key={`span-${chunk}`}>{chunk}</span>
        )))}
    </span>
  ), [highlightChunks]);

  return React.useMemo(() => (
    <ApplicationWindow path={path}>
      {({ onOpen }) => (
        <ApplicationListItem
          content={content}
          description={description}
          path={path}
          actions={[
            { ...OpenAction, onClick: onOpen },
          ]}
          link
        />
      )}
    </ApplicationWindow>
  ), [
    OpenAction,
    description,
    content,
    path,
  ]);
};
