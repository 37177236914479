// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const favoriteHeader = 'Favoris';
export const favoriteEmpty = 'Aucun favori';

export const favoriteAdd = 'Ajouter aux favoris';
export const favoriteDelete = 'Supprimer des favoris';
export const favoriteReorder = 'Réorganiser les favoris';

export const favoriteGroupAdd = 'Créer un groupe';
