// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as path from 'path';
import * as _ from 'lodash';
import * as React from 'react';
import { AxiosInstance } from 'axios';

import { useEffect, useEventCallback } from '@jvs-group/jvs-mairistem-tools';

import * as host from '../../utils/host';
import { loadManifest, unloadManifest } from '../../utils/manifest';

import { ApplicationPageContext } from './ApplicationPageContext';
import { ApplicationSessionContext } from '../ApplicationSession';
import { ApplicationNavigationContext } from '../ApplicationNavigation';

import { FeatureService } from '../../entities/Feature';
import { MenuService } from '../../entities/Menu';
import { SpeedDialService } from '../../entities/SpeedDial';

import { useAxios } from '../../utils/axios';
import { isUrl } from '../../utils/path';

import { useHistory } from '../../services/router';

type ApplicationManifest = {
  identifiant: string | number,
  features: { id: string },
  menus: { id: string },
  speedDial?: { id: string, name: string, buttons: [] }
};

type ApplicationService = {
  identifiant: string | number,
  initialized: boolean,
  request: AxiosInstance
};

export type ApplicationPageContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationPageContextProviderType = React.ComponentType<ApplicationPageContextProviderProps>;

export const ApplicationPageContextProvider: ApplicationPageContextProviderType = (
  props: ApplicationPageContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationPageContext;

  const { user } = React.useContext(ApplicationSessionContext);
  const { module } = React.useContext(ApplicationNavigationContext);

  const [manifest, setManifest] = React.useState<ApplicationManifest>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [service, setService] = React.useState<ApplicationService>();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const history = useHistory();

  const { request, response } = useAxios(user, module);

  const coerceManifest = useEventCallback((
    value: Partial<ApplicationManifest>,
    identifiant: string | number,
    pathname: string,
  ) => {
    const man = {
      identifiant,
      ...value,
      features: _.map(value.features, (feature: { path: string; }) => ({
        ...feature, path: isUrl(feature.path) ? feature.path : path.join(pathname, feature.path),
      })),
      menus: _.map(value.menus, (menu) => ({
        ...menu, path: isUrl(menu.path) ? menu.path : path.join(pathname, menu.path),
      })),
      ...(value.speedDial && {
        speedDial: {
          ...value.speedDial,
          buttons: _.map(value.speedDial?.buttons, (button) => ({
            ...button, path: isUrl(button.path) ? button.path : path.join(pathname, button.path),
          })),
        },
      }),
    } as ApplicationManifest;

    React.startTransition(() => {
      FeatureService.addFeatureByManifest(man, user);
      MenuService.addMenuByManifest(man, user);
      SpeedDialService.addSpeedDialByManifest(man, user);
    });

    return man;
  });

  const coerceService = useEventCallback((
    value: ApplicationService,
    identifiant: string | number,
  ) => {
    const svc = {
      identifiant,
      ...value,
    };

    if (!svc.initialized) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      svc.request.interceptors.request.use(request.data, request.error);
      svc.request.interceptors.response.use(response.data, response.error);

      svc.initialized = true;
    }

    return svc;
  });

  const loadModule = React.useCallback((asset, identifiant, hostname, pathname) => {
    const initialize = async (mod) => {
      const svc = await mod.configure({ history });
      const man = await mod.load(pathname, hostname);

      React.startTransition(() => {
        setService(coerceService(svc, identifiant));
        setManifest(coerceManifest(man, identifiant, pathname));
      });
    };

    if (asset) {
      initialize(asset);
    }
  }, []);

  const unloadModule = React.useCallback((id) => {
    React.startTransition(() => {
      setManifest(null);
      setService(null);
      setError(false);
    });

    unloadManifest(id);
  }, []);

  const requireModule = React.useCallback(async (id, hostname) => {
    if (hostname) {
      React.startTransition(() => {
        setLoading(true);
      });

      try {
        return await loadManifest(id, hostname);
      } catch {
        React.startTransition(() => {
          setError(true);
        });
      }
    }

    return null;
  }, []);

  useEffect(() => {
    if (module) {
      requireModule(module.code, host.app(module.repertoire))
        // eslint-disable-next-line max-len
        .then((value) => { loadModule(value, module.identifiant, host.app(module.repertoire), module.adresse); })
        .catch(() => { setError(true); })
        .finally(() => { setLoading(false); });
    }

    return () => {
      if (module) {
        unloadModule(module.code);
      }
    };
  }, [module]);

  return React.useMemo(() => (
    <Provider
      value={
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                manifest,
                loading,
                error,
              }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    manifest,
    loading,
    error,
  ]);
};
