// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { Input, Icon } from 'semantic-ui-react';

import './ApplicationMenuSearch.less';

import * as i18n from '../../config/i18n';

import { ApplicationMenuContext } from './ApplicationMenuContext';

import { ApplicationMenuItem } from './ApplicationMenuItem';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('menu', 'search');

export type ApplicationMenuSearchProps = {
  /** */
  value: string;

  /** */
  onChange: (value?: string) => void
};

export type ApplicationMenuSearchType = React.ComponentType<ApplicationMenuSearchProps>;

export const ApplicationMenuSearch: ApplicationMenuSearchType = (
  props: ApplicationMenuSearchProps,
) => {
  const {
    value,
    onChange,
  } = props;

  const { open, setOpen } = React.useContext(ApplicationMenuContext);

  const ref = React.useRef<HTMLInputElement>();

  const [search, setSearch] = React.useState(value);

  const handleClick = React.useCallback(() => {
    React.startTransition(() => {
      setOpen(true);
    });

    ref.current?.focus();
  }, []);

  const handleChange = React.useCallback(({ target: { value: input } }) => {
    React.startTransition(() => {
      setSearch(input);
    });
  }, []);

  const handleClear = React.useCallback(() => {
    React.startTransition(() => {
      setSearch('');
    });
  }, []);

  React.useEffect(_.debounce(() => {
    onChange?.(search);
  }, 250), [
    search,
    onChange,
  ]);

  React.useLayoutEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current?.focus();
      }, 50);
    }
  }, [open]);

  const clearIcon = React.useMemo(() => (
    <Icon name="close" onClick={handleClear} link />
  ), [handleClear]);

  return (
    <ApplicationMenuItem icon="search" content={i18n.search} onClick={handleClick}>
      <Input
        data-testid="menu.search"
        className={className}
        placeholder={i18n.searchFeaturePlaceholder}
        value={search}
        onChange={handleChange}
        icon={!_.isEmpty(search) && clearIcon}
        transparent
        fluid
      >
        <input ref={ref} />
      </Input>
    </ApplicationMenuItem>
  );
};
