// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationEnvironmentContextProvider } from './ApplicationEnvironmentContextProvider';

export type ApplicationEnvironmentProps = {
  /** */
  children: React.ReactNode;
};

export type ApplicationEnvironmentType = React.ComponentType<ApplicationEnvironmentProps>;

export const ApplicationEnvironment: ApplicationEnvironmentType = (
  props: ApplicationEnvironmentProps,
) => {
  const {
    children,
  } = props;

  return (
    <ApplicationEnvironmentContextProvider>
      {children}
    </ApplicationEnvironmentContextProvider>
  );
};
