// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import Axios from 'axios';

import { factory } from './axios';

import { host } from '../utils/host';

const axios = Axios.create({ baseURL: host });

export default factory(axios);
