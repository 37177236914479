// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Menu } from 'semantic-ui-react';

import './ApplicationLauncherMenuAction.less';

import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('launcher', 'menu', 'action');

export type ApplicationLauncherMenuActionProps = {
  /** */
  icon?: React.ReactNode;

  /** */
  content?: React.ReactNode;

  /** */
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: ApplicationLauncherMenuActionProps
  ) => void;
};

// eslint-disable-next-line max-len
export type ApplicationLauncherMenuActionType = React.ComponentType<ApplicationLauncherMenuActionProps>;

export const ApplicationLauncherMenuAction: ApplicationLauncherMenuActionType = (
  props: ApplicationLauncherMenuActionProps,
) => {
  const {
    icon,
    content,
    onClick,
  } = props;

  const handleClick = React.useCallback((event) => {
    onClick?.(event, props);
  }, [
    onClick,
    props,
  ]);

  return React.useMemo(() => (
    <Menu.Item className={className} icon={icon} content={content} onClick={handleClick} />
  ), [
    className,
    icon,
    content,
    handleClick,
  ]);
};
