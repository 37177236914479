// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { useSessionStorage } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationFrameContext } from '../ApplicationFrame';

import { ApplicationLauncherContext } from './ApplicationLauncherContext';

export type ApplicationLauncherContextProviderProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

// eslint-disable-next-line max-len
export type ApplicationLauncherContextProviderType = React.ComponentType<ApplicationLauncherContextProviderProps>;

export const ApplicationLauncherContextProvider: ApplicationLauncherContextProviderType = (
  props: ApplicationLauncherContextProviderProps,
) => {
  const { children } = props;

  const { Provider } = ApplicationLauncherContext;

  const { launcher: { open, setOpen } } = React.useContext(ApplicationFrameContext);

  const [visible, setVisible] = useSessionStorage('interaction.launcher.visible', true);

  return React.useMemo(() => (
    <Provider
      value={
                {
                  open,
                  setOpen,

                  visible,
                  setVisible,
                }
            }
    >
      {children}
    </Provider>
  ), [
    children,
    open,
    visible,
    setOpen,
    setVisible,
  ]);
};
