// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Divider, Sidebar, List, Popup, Button, Icon, Placeholder,
  SemanticICONS,
} from 'semantic-ui-react';

import './ApplicationListItem.less';

import {
  ApplicationListItemAction,
  ApplicationListItemActionProps,
} from './ApplicationListItemAction';

import { Link } from '../../services/router';

import {
  getApplicationClassName, getClassName,
} from '../utils/className';

type Action = React.ReactElement<ApplicationListItemActionProps> | ApplicationListItemActionProps;

export type ApplicationListItemProps = {
  /** */
  className?: string;

  /** */
  name?: string;

  /** */
  actions?: Action[];

  /** */
  content?: React.ReactNode;

  /** */
  description?: React.ReactNode;

  /** */
  direction?: 'column' | 'row';

  /** */
  disabled?: boolean;

  /** */
  header?: React.ReactNode;

  /** */
  icon?: string | React.ReactElement;

  /** */
  link?: boolean;

  /** */
  exact?: boolean;

  /** */
  path?: string;

  /** */
  offset?: number;

  /** */
  popup?: React.ReactNode;

  /** */
  placeholder?: boolean;

  /** */
  title?: string | React.ReactNode;

  /** */
  selected?: boolean;

  /** */
  sorting?: boolean;

  /** */
  removing?: boolean;

  /** */
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    data: ApplicationListItemProps
  ) => void;

  /** */
  onDrag?: (
    event: React.SyntheticEvent<HTMLElement>
  ) => void;

  /** */
  onIndicate?: (
    event: React.MouseEvent<HTMLElement>
  ) => void;

  [key: string]: unknown;
};

export type ApplicationListItemType = React.ComponentType<ApplicationListItemProps>;

export const ApplicationListItem: ApplicationListItemType = (
  props: ApplicationListItemProps,
) => {
  const {
    className,
    name,
    actions,
    content,
    description,
    direction = 'row',
    disabled,
    header,
    icon,
    link,
    exact,
    path,
    offset = 0,
    popup,
    title,
    placeholder,
    selected,
    sorting, // eslint-disable-line @typescript-eslint/no-unused-vars
    removing, // eslint-disable-line @typescript-eslint/no-unused-vars
    onClick,
    onIndicate,
    ...rest
  } = props;

  const label = header || content;

  const handleClick = React.useCallback((event) => {
    onClick?.(event, props);
  }, [
    onClick,
    props,
  ]);

  const handleIndicatorClick = React.useCallback((event) => {
    onIndicate?.(event);
  }, [onIndicate]);

  const actionsComponent = React.useMemo(() => (
    !_.isEmpty(_.filter(actions, Boolean))
      && (
      <List.Content floated="right">
        <Button.Group
          className={getClassName(direction)}
          basic
          compact
          icon
          size="small"
        >
          {_.map(_.filter<Action>(actions, Boolean), (action, key) => (
            React.isValidElement(action)
              ? action
              : (
                <ApplicationListItemAction
                  key={key}
                  content={action.content}
                  icon={action.icon}
                  title={action.title}
                  onClick={action.onClick}
                />
              )
          ))}
        </Button.Group>
      </List.Content>
      )
  ), [
    actions,
    direction,
  ]);

  const iconComponent = React.useMemo(() => (
    React.isValidElement(icon) ? icon : <Icon name={(icon as SemanticICONS)} />
  ), [icon]);

  const itemComponent = React.useMemo(() => (
    header
      ? (
        <>
          <List.Header>
            {icon && iconComponent}
            {header}
          </List.Header>
          <List.Content>
            {content}
          </List.Content>
          <List.Description>
            {description}
          </List.Description>
        </>
      )
      : (
        <>
          {icon && iconComponent}
          {content}
        </>
      )
  ), [
    icon,
    header,
    content,
    description,
  ]);

  const triggerComponent = React.useMemo(() => (
    <List.Content
      verticalAlign="middle"
      style={{ paddingLeft: `${offset + 1}em` }}
    >
      {itemComponent}
    </List.Content>
  ), [
    offset,
    header,
    content,
    description,
  ]);

  const contentComponent = React.useMemo(() => (
    <List.Content>
      {title || itemComponent}
    </List.Content>
  ), [title, itemComponent]);

  const popupComponent = React.useMemo(() => (
    <Popup
      position="right center"
      trigger={triggerComponent}
      content={contentComponent}
      disabled={!popup}
      mouseEnterDelay={2000}
    />
  ), [
    popup,
    contentComponent,
    triggerComponent,
  ]);

  const placeholderComponent = React.useMemo(() => (
    <List.Content as={Placeholder} fluid>
      <List.Header as={Placeholder.Header} />
      <List.Content
        as={Placeholder.Line}
        length="medium"
      />
      <List.Description
        as={Placeholder.Line}
        length="long"
      />
    </List.Content>
  ), []);

  const indicator = React.useMemo(() => (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus, jsx-a11y/control-has-associated-label
    <div
      role="button"
      className={getClassName('indicator', { selected })}
      onClick={handleIndicatorClick}
    >
      <Sidebar.Pushable>
        <Sidebar />
      </Sidebar.Pushable>
    </div>
  ), [
    selected,
    handleIndicatorClick,
  ]);

  const item = React.useMemo(() => (
    placeholder
      ? placeholderComponent
      : (
        <>
          {actionsComponent}
          {popupComponent}
        </>
      )
  ), [
    placeholder,
    placeholderComponent,
    actionsComponent,
    popupComponent,
  ]);

  const linkableItem = React.useMemo(() => (
    <List.Item
      className={getClassName(className, getApplicationClassName('list', 'item'))}
      aria-label={_.isString(label) ? label : ''}
      data-testid={name}
      as={Link}
      to={{ pathname: path }}
      exact={exact}
      disabled={disabled}
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {item}
    </List.Item>
  ), [
    className,
    name,
    label,
    path,
    disabled,
    handleClick,
    rest,
  ]);

  const listableItem = React.useMemo(() => (
    <List.Item
      className={getClassName(className, getApplicationClassName('list', 'item'))}
      aria-label={_.isString(label) ? label : ''}
      data-testid={name}
      disabled={disabled}
      active={selected}
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {indicator}
      {item}
    </List.Item>
  ), [
    className,
    name,
    label,
    indicator,
    selected,
    disabled,
    handleClick,
    rest,
  ]);

  return React.useMemo(() => (
    <>
      {
        link
          ? linkableItem
          : listableItem
      }
      {direction === 'column' && <Divider clearing fitted />}
    </>
  ), [
    link,
    linkableItem,
    listableItem,
    direction,
  ]);
};
