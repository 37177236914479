// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Sidebar } from 'semantic-ui-react';

import { ApplicationFrameContext } from './ApplicationFrameContext';

import { ApplicationMenu } from '../ApplicationMenu';

export type ApplicationFrameMenuProps = {
  /** Primary content. */
  children?: React.ReactNode;
};

export type ApplicationFrameMenuType = React.ComponentType<ApplicationFrameMenuProps>;

export const ApplicationFrameMenu: ApplicationFrameMenuType = (
  props: ApplicationFrameMenuProps,
) => {
  const { children } = props;

  const { menu: { open } } = React.useContext(ApplicationFrameContext);

  return React.useMemo(() => (
    <Sidebar.Pushable>
      <ApplicationMenu />
      <Sidebar.Pusher dimmed={open}>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  ), [
    open,
    children,
  ]);
};
