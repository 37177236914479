// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Header, Label,
} from 'semantic-ui-react';

import './ApplicationSearchCategory.less';

import { getApplicationClassName, getClassName } from '../utils/className';

const className = getApplicationClassName('search', 'category');

export declare type ApplicationSearchCategoryProps = {
  /** */
  name?: string;

  /** */
  code?: string;

  /** */
  count?: number;

  /** */
  onClick?: (event: React.SyntheticEvent) => void;
};

export type ApplicationSearchCategoryType = React.ComponentType<ApplicationSearchCategoryProps>;

export const ApplicationSearchCategory: ApplicationSearchCategoryType = (
  props: ApplicationSearchCategoryProps,
) => {
  const {
    code,
    name,
    count,
    onClick,
  } = props;

  return React.useMemo(() => (
    <Header
      className={getClassName(className, code)}
      size="tiny"
      onClick={onClick}
      block
    >
      {name}
      <Header size="tiny" floated="right">
        <Label circular size="tiny">{count}</Label>
      </Header>
    </Header>
  ), [
    code,
    count,
    name,
  ]);
};
