// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { toast } from 'react-toastify';

export const debug = (message) => {
  toast.info(`[DEBUG]: ${message}`, { toastId: 'DEBUG', autoClose: false, icon: '🛠️' });
};

export const error = (code) => {
  switch (code) {
    case 403:
      toast.error(
        "Vous n'avez pas les droits pour acceder a cette resource",
        { toastId: 'ERR_FORBIDDEN', autoClose: false, icon: '🚫' },
      );
      break;
    default:
      if (code > 499) {
        // toast.error(
        //   "Une erreur réseau s'est produite durant l'envois de la requête",
        //   { toastId: 'ERR_COMPONENT_NETWORK' },
        // );
      }
      break;
  }
};
