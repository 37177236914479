// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

export const useState = <S>(
  initialState?: S | (() => S),
): [S | undefined, (value: S) => void] => {
  const [state, setState] = React.useState(initialState);
  const ref = React.useRef<boolean>();

  const setSafeState = React.useCallback((currentState: S) => {
    if (!ref.current) return;

    setState(currentState);
  }, []);

  React.useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  }, []);

  return [
    state,
    setSafeState,
  ];
};
